import { FC, Fragment, useEffect, useState, useRef, ReactNode } from 'react'
import ContentSectionLayout from '../ContentSectionLayout'
import { Crosshair, CustomDeleteIcon, PlusIcon, PaintBrushBroadIcon } from 'components/icons'
import { Checkbox, Flexbox, IconButtonComponent, TextField, Typography, ConfirmationDialog, CommonButton } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Paper, Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { editModeSelector, liveDateSelector, okrsSelector, setEditMode, setOkrField, setOkrs } from 'store/initiative-slice'
import EmptyView from '../EmptyView';
import { okrsSelector as okrsDataSelector } from 'store/okrs';
import { getOkrs } from 'pages/Okrs/okrs.api';
import { OKR, InitiativeOKR } from 'utils/types';
import SearchableSelect, { Option } from 'components/SearchableSelect';
import SearchIcon from '@mui/icons-material/Search';

const classes = classNames.bind(styles);

interface OkrSelectContentProps {
    options: Option[];
    value: number[];
    onSelect?: (value: number[]) => void;
    onChange?: (value: number[]) => void;
}

const OkrSelectContent: FC<OkrSelectContentProps> = ({ options, value, onSelect, onChange }) => {
    const [searchText, setSearchText] = useState('');
    const [selectedValues, setSelectedValues] = useState<number[]>(value);
    // Set initial values from props
    useEffect(() => {
        setSelectedValues(value);
    }, [value]);
    const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
    );
    const handleCheckOption = (optionValue: number) => {
        const newValue = selectedValues.includes(optionValue)
            ? selectedValues.filter(v => v !== optionValue)
            : [...selectedValues, optionValue];
        setSelectedValues(newValue);
        // Notify parent of change, but don't submit yet
        if (onChange) {
            onChange(newValue);
        }
    };
    // Add method to get the current selection
    const getSelectedValues = () => {
        return selectedValues;
    };
    // Expose the getSelectedValues method
    if (typeof onSelect === 'function') {
        (onSelect as any).getValues = getSelectedValues;
    }
    return (
        <Flexbox vertical className={classes('okr-select-popup')}>
            <Typography variant="body1" className={classes('okr-select-title')}>
                Select which OKRs will be affected by this initiative:
            </Typography>
            <TextField
                className={classes('okr-search-input')}
                placeholder="Search OKRs..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Flexbox vertical className={classes('okr-options-list')}>
                {filteredOptions.length === 0 ? (
                    <Typography variant="body2" className={classes('no-results')}>
                        No OKRs found
                    </Typography>
                ) : (
                    filteredOptions.map(option => (
                        <Flexbox
                            key={option.value}
                            align
                            className={classes('okr-option-item', {
                                'selected': selectedValues.includes(option.value as number)
                            })}
                            onClick={() => handleCheckOption(option.value as number)}
                        >
                            <Checkbox
                                checked={selectedValues.includes(option.value as number)}
                                size="small"
                                className={classes('option-checkbox')}
                            />
                            <Typography variant="body2" className={classes('option-label')}>
                                {option.label}
                            </Typography>
                        </Flexbox>
                    ))
                )}
            </Flexbox>
        </Flexbox>
    );
};

// Custom empty view without icon and title
const OkrEmptyView: FC<{ description: string, customActions?: ReactNode }> = ({ description, customActions }) => {
    return (
        <EmptyView
            title=""
            description={description}
            showDefaultIcon={false}
            customActions={customActions}
        />
    );
};

interface OkrImpactProps {
}
const columns = ['Key Results', 'Current', 'Target', 'Initiative Impact'];
const OkrImpact: FC<OkrImpactProps> = () => {
    const dispatch = useDispatch();

    const editMode = useSelector(editModeSelector);
    const okrs = useSelector(okrsSelector);

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [itemToRemove, setItemToRemove] = useState<InitiativeOKR | null>(null);
    const [filteredOkrs, setFilteredOkrs] = useState<OKR[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOkrs, setSelectedOkrs] = useState<number[]>([]);
    const releaseDate = useSelector(liveDateSelector);
    // Get all okrs from the store
    const okrsData = useSelector(okrsDataSelector);
    useEffect(() => {
        dispatch(getOkrs());
    }, [])

    useEffect(() => {
        if (okrsData.length) {
            const filterByDate = okrsData.filter(okr => {
                const okrEndDate = okr.endDate ? new Date(okr.endDate) : null

                if (okrEndDate) {
                    okrEndDate.setHours(okrEndDate.getHours() + 24);
                }
                if (!releaseDate || !okrEndDate || releaseDate <= okrEndDate) {
                    return okr
                }
            })
            setFilteredOkrs(filterByDate)
        }
    }, [okrsData, releaseDate])

    const onSelectOkr = (value: number[]) => {
        dispatch(setEditMode(true));
        const newOkrs = value.map(okr => {
            const exsOkr = okrs.find((pOkr: InitiativeOKR) => pOkr.okrId === okr);

            if (exsOkr) {
                return exsOkr;
            } else {
                return {
                    okrId: okr,
                    contributions: okrsData.find((el: OKR) => el.id === okr)?.keyResults.map(kr => ({
                        keyResult: kr,
                        checked: true
                    }))
                };
            }
        });
        dispatch(setOkrs(newOkrs));
    };

    const handleConfirmDelete = () => {
        if (itemToRemove) {
            dispatch(setOkrs(okrs.filter((okr: InitiativeOKR) => okr.okrId !== itemToRemove.okrId)));
            setOpenDeleteConfirmation(false);
            setItemToRemove(null);
        }
    };
    const handleCancelDelete = () => {
        setOpenDeleteConfirmation(false);
        setItemToRemove(null);
    };
    const handleAddOkr = () => {
        if (!editMode) {
            dispatch(setEditMode(true));
        }
        // Initialize selected OKRs with current OKRs
        setSelectedOkrs(okrs.map((okr: InitiativeOKR) => okr.okrId));
        setShowDropdown(true);
    };
    const handleSelectOkr = (value: number[]) => {
        onSelectOkr(value);
        setShowDropdown(false);
    };

    const handleOkrChange = (value: number[]) => {
        setSelectedOkrs(value);
    };

    const handleConfirmSelection = () => {
        handleSelectOkr(selectedOkrs);
    };

    return (
        <ContentSectionLayout
            title="OKRs/Goals"
            icon={<Crosshair />}
            id='initiative-impact'
            isEmpty={okrs.length === 0}
            dataSectionId='impact'
        >
            {okrs.length === 0 ? (
                <OkrEmptyView
                    description='Define clear objectives and key results to measure success and track progress.'
                    customActions={
                        <>
                            <SearchableSelect
                                options={filteredOkrs.map(okr => ({ label: okr.objective || '', value: okr.id }))}
                                value={okrs.map((okr: any) => okr.okrId)}
                                onSelect={(value) => onSelectOkr(value)}
                                title={'Affected OKR (Filtered by live date)'}
                                placeholder={'Select From List'}
                                multiSelect={true}
                                position='center'
                            />
                        </>
                    }
                />
            ) : (
                <Flexbox vertical className={classes('okr-impact-container')} data-section-id='okr-impact'>
                    {
                        okrs.map((okr: InitiativeOKR, index: number) => (
                            <Fragment key={index}>
                                <Flexbox justifyBetween align className={classes('okr-impact-container-header')}>
                                    <Typography className={classes('title')}>{okrsData.find(fOkr => fOkr.id === okr.okrId)?.objective}</Typography>
                                    {editMode && (
                                        <IconButtonComponent buttonSize='small' buttonType='with-background'
                                            onClick={() => {
                                                setOpenDeleteConfirmation(true);
                                                setItemToRemove(okr);
                                            }}
                                        >
                                            <CustomDeleteIcon />
                                        </IconButtonComponent>
                                    )}
                                </Flexbox>
                                {okr.contributions?.length > 0 && (
                                    <TableContainer component={Paper} className={classes('okr-impact-container-table')}>
                                        <MuiTable aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell key={column}>{column}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {okr.contributions.map((row: any) => {
                                                    const okrData = filteredOkrs.find(fOkr => fOkr.id === okr.okrId);
                                                    const krData = okrData?.keyResults.find(fKr => fKr.id === row.keyResult.id);

                                                    return (
                                                        <TableRow
                                                            key={row.keyResult.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Flexbox justifyBetween align>
                                                                    {editMode && (
                                                                        <Checkbox
                                                                            className={classes('checkbox')}
                                                                            size='small'
                                                                            checked={row.checked}
                                                                            onChange={(e) => {
                                                                                dispatch(setOkrField({ id: okr.okrId, krId: row.keyResult.id, field: 'checked', value: !row.checked }))
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {row.keyResult.metric.name}
                                                                </Flexbox>
                                                            </TableCell>
                                                            <TableCell>{row.keyResult.metric.uom} {krData?.metric.currentValue}</TableCell>
                                                            <TableCell>{row.keyResult.metric.uom} {krData?.targetValue}</TableCell>
                                                            {editMode ? (
                                                                <TableCell>
                                                                    <TextField
                                                                        type='number'
                                                                        className={classes('text-field')}
                                                                        onChange={(e) => {
                                                                            dispatch(setOkrField({ id: okr.okrId, krId: row.keyResult.id, field: 'initiativeImpact', value: e.target.value }))
                                                                        }}
                                                                        value={row.initiativeImpact}
                                                                    />
                                                                </TableCell>
                                                            ) : (
                                                                <TableCell>{row.initiativeImpact}</TableCell>
                                                            )}
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </MuiTable>
                                    </TableContainer>
                                )}
                            </Fragment>
                        ))
                    }
                </Flexbox>
            )}
            {showDropdown && (
                <ConfirmationDialog
                    open={true}
                    onClose={() => setShowDropdown(false)}
                    onConfirm={handleConfirmSelection}
                    confirmButtonStyle='normal'
                    confirmButtonLabel='Done'
                    title='Select OKRs'
                >
                    <OkrSelectContent
                        options={filteredOkrs.map(okr => ({ label: okr.objective || '', value: okr.id }))}
                        value={selectedOkrs}
                        onChange={handleOkrChange}
                    />
                </ConfirmationDialog>
            )}
            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                confirmButtonStyle='danger'
                title='Remove this OKR?'
            >
                <Flexbox>
                    Are you sure you want to remove this OKR from this section?
                </Flexbox>
            </ConfirmationDialog>
        </ContentSectionLayout>
    )
}

export default OkrImpact;