import { CommonButton, Flexbox, Snackbar, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { PenIcon } from 'components/icons'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeOverviewField,
    currentInitiativeSelector,
    customSectionInteractionSelector,
    editModeSelector,
    flattenInitiative,
    initiativeTitleSelector,
    removingAttachmentIdsSelector,
    selectedTemplateSelector,
    sessionIdSelector,
    setCustomSectionInteraction,
    setEditMode,
    setRemovingAttachmentIds
} from 'store/initiative-slice'
import { useCreateInitiativeCustomSectionsMutation, useUpdateInitiativeV2Mutation } from 'store/initiative-api'
import { useWorkspaceId } from 'utils/hooks'
import { deleteBlockFromKB } from 'store/knowledgeBase-api'
import { useParams } from 'react-router-dom'

const classes = classNames.bind(styles)

interface HeadingProps { }

const Heading: FC<HeadingProps> = () => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();
    const params = useParams();

    const [updateInitiativeV2, { isLoading: isUpdatingInitiative }] = useUpdateInitiativeV2Mutation();
    const [createInitiativeCustomSections] = useCreateInitiativeCustomSectionsMutation();

    const title = useSelector(initiativeTitleSelector);
    const editMode = useSelector(editModeSelector);
    const currentInitiative = useSelector(currentInitiativeSelector);
    const sessionId = useSelector(sessionIdSelector);
    const removingAttachmentIds = useSelector(removingAttachmentIdsSelector);
    const selectedTemplate = useSelector(selectedTemplateSelector);
    const customSectionInteraction = useSelector(customSectionInteractionSelector);

    const [successMessage, setSuccessMessage] = useState('');

    const handleSaveInitiative = async () => {
        if (customSectionInteraction) {
            await createInitiativeCustomSections({
                workspaceId,
                initiativeId: params.id,
                newSections: selectedTemplate?.content,
            });
            dispatch(setCustomSectionInteraction(false));
        }

        const response = await updateInitiativeV2({
            workspaceId,
            initiativeId: params.id,
            initiative: {
                fields: flattenInitiative(currentInitiative || {}),
                chatSessionId: sessionId,
            }
        });

        if (removingAttachmentIds.length > 0) {
            removingAttachmentIds.forEach(id => {
                dispatch(deleteBlockFromKB(id));
            });
        }

        if ('data' in response) {
            dispatch(setRemovingAttachmentIds([]));
            setSuccessMessage('Initiative updated successfully');
        }
    }

    const handleChangeModeOrSave = () => {
        if (editMode) {
            handleSaveInitiative();
        }

        dispatch(setEditMode(!editMode));
    }

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(changeOverviewField({ field: 'title', value: e.target.value }));
    }

    return (
        <Flexbox vertical fullWidth className={classes('heading-container')} id='initiative-heading'>
            <Flexbox fullWidth align justifyBetween className={classes('heading', 'gap-3')}>
                <Flexbox fullWidth className={classes('heading-left')}>
                    {
                        editMode ? (
                            <Flexbox className={classes('title-editor', 'edit', 'gap-1')}>
                                <Flexbox align className={classes('title-editor-input-container')}>
                                    <input type='text' value={title} className={classes('title-editor-input')} onChange={handleTitleChange} />
                                </Flexbox>
                                <PenIcon />
                            </Flexbox>
                        ) :
                            <Flexbox align className={classes('title-editor')}>
                                <Typography className={classes('title-editor-text')}>{title}</Typography>
                            </Flexbox>
                    }
                </Flexbox>

                <CommonButton
                    buttonSize='medium'
                    buttonType={editMode ? 'action' : 'shadow'}
                    onClick={handleChangeModeOrSave}
                    disabled={isUpdatingInitiative}
                    loading={isUpdatingInitiative}
                    className={!editMode ? classes('edit-button') : ''}
                >
                    {editMode ? 'Save' : 'Edit'}
                </CommonButton>
            </Flexbox>

            <Snackbar open={!!successMessage} autoHideDuration={3000} onClose={() => setSuccessMessage('')} message={successMessage} type='success'>
                <Flexbox>{successMessage}</Flexbox>
            </Snackbar>
        </Flexbox>
    )
}

export default Heading