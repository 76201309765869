import { getMenuItemsArray } from 'common/mainLayout/Sidebar/menuItems';
import { useEffect, useRef, useState } from 'react';
import store from 'store';
import { SidebarMenuItem } from './types';
import posthog from 'posthog-js';
import { setSelectedTemplate } from 'store/initiative-slice';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { selectedTemplateSelector, setEditMode, addCustomSection, addNewCustomSection } from 'store/initiative-slice';
import { FileText } from 'components/icons';

export const useDebounce = <T extends unknown>(
    initialValue: T,
    timeout: number = 500
): [T, T, (value: T) => void] => {
    const [value, setValue] = useState<T>(initialValue);
    const [debounceValue, setDebounceValue] = useState<T>(initialValue);
    const timer = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            setDebounceValue(value);
        }, timeout);

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [value]);
    return [value, debounceValue, setValue];
};

export const useWorkspaceId = () => store.getState().user.workspace.id

export const useFirstAvailableMenuItem = () => {
    const menuItems = getMenuItemsArray();
    const distinctId = posthog.get_distinct_id()

    const [menuItem, setMenuItem] = useState<SidebarMenuItem>()

    useEffect(() => {
        if (distinctId) {
            const menuItemsWithFlagStatus = menuItems.map(item => ({
                ...item,
                isEnabled: item.featureFlag ? posthog.isFeatureEnabled(item.featureFlag) : true
            }));

            const enabledMenuItem = menuItemsWithFlagStatus.find(el => el.isEnabled);

            if (enabledMenuItem && enabledMenuItem.url === '' && enabledMenuItem.sections && enabledMenuItem.sections.length > 0) {
                setMenuItem(enabledMenuItem.sections[0]);
            }

            setMenuItem(enabledMenuItem)
        }
    }, [distinctId])

    return menuItem as SidebarMenuItem;
}

export const useAddCustomSection = () => {
    const dispatch = useDispatch();
    const selectedTemplate = useSelector(selectedTemplateSelector);

    const handleAddCustomSection = (value?: {
        title: string,
        description: string,
        field_generation_id?: string
    }) => {
        const id = uuidv4();

        dispatch(setEditMode(true));

        if (!selectedTemplate) { return; }

        const newSectionField = {
            id: id,
            type: 'TEXT',
            options: [],
            fieldKey: 'custom' + id,
            uiConfig: {
                elementType: 'input'
            },
            isRequired: true,
            description: '',
            displayName: '',
            defaultValue: '',
            instructions: '',
            outputSchema: {
                type: 'object',
                required: ['title', 'description'],
                properties: {
                    title: {
                        type: 'string',
                        description: 'The name of the section.'
                    },
                    description: {
                        type: 'string',
                        description: 'Custom section content.'
                    }
                }
            },
            aiProcessingMode: 'ai-generate'
        };

        const newTemplate = {
            ...selectedTemplate,
            content: {
                ...selectedTemplate.content,
                sections: [...selectedTemplate.content.sections, {
                    key: 'custom' + id,
                    name: 'Untitled Section',
                    order: selectedTemplate.content.sections.length + 1,
                    fields: [newSectionField]
                }]
            }
        };

        dispatch(setSelectedTemplate(newTemplate));

        dispatch(addCustomSection({
            sectionKey: 'custom' + id,
            sectionData: [{
                type: 'text',
                displayName: '',
                fieldKey: 'custom' + id,
                options: [],
                outputSchema: newSectionField.outputSchema,
                uiConfig: {
                    elementType: 'input'
                },
                isRequired: true,
                value: {
                    title: value?.title || '',
                    description: value?.description || '',
                    field_generation_id: value?.field_generation_id || null
                }
            }]
        }));

        dispatch(addNewCustomSection({
            title: value?.title || 'Untitled Section',
            id: 'custom' + id,
            icon: FileText
        }));
    };

    return { handleAddCustomSection };
};

export default useAddCustomSection;