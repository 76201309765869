import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AttachmentMetadata, Block, ContentType, GenerationPlan, Initiative, Message } from 'utils/types'
import { RootState } from 'store';
import { initiativeApi } from './initiative-api';
import { BroadcastIcon, BugIcon, ChatCenteredDotsIcon, CheckCircleIconCustom, CircleWavyCheckIcon, Crosshair, FileText, PaintBrushBroadIcon } from 'components/icons';

// TODO: Make this a global function, to handle outputSchema and properties together
function arrayToObject(arr: string[]) {
    const result: Record<string, any> = {};
    for (const key of arr) {
        result[key] = '';
    }
    return result;
}
export interface InitiativeTemplateSectionToShow {
    title: string;
    id: string;
    icon: (() => JSX.Element) | null;
}
export interface InitiativeTemplateField {
    id: number;
    fieldKey: string;
    fieldName: string;
    isRequired: boolean;
    options: any[];
    type: string;
    outputSchema: any;
    uiConfig: any;
    defaultValue: any;
    displayName: string;
}

export interface InitiativeTemplateSection {
    order: number;
    name: string;
    key: string;
    fields: InitiativeTemplateField[];
}

export interface InitiativeTemplateV2 {
    templateId: number;
    templateVersionId: number;
    templateName: string;
    versionId: number;
    content: {
        createdAt: string;
        updatedAt: string;
        sections: InitiativeTemplateSection[];
    };
}

export interface CreateInitiativeV2Payload {
    title: string;
    creatorId: number;
    templateId: number;
    templateVersionId: number;
    fields: Record<string, any>;
}

export interface InitiativeFieldConfig {
    id: number;
    type: string;
    options: any[];
    fieldKey: string;
    uiConfig: any;
    isRequired: boolean;
    description: string;
    displayName: string;
    defaultValue: any;
    instructions: string;
    outputSchema: any;
}
export interface InitiativeField {
    value?: any;
    config?: InitiativeFieldConfig;
    aiProcessingMode?: string;
    fieldKey: string;
}

export interface InitiativeV2 {
    id: number;
    name: string | null;
    description: string | null;
    templateId: number;
    templateVersionId: number;
    fields: Record<string, any>;
}

const mapContentType = (type: string) => {
    switch (type) {
        case 'TYPE': return ContentType.INITIATIVE_TYPE;
        case 'PRIORITY': return ContentType.INITIATIVE_PRIORITY;
        case 'SIZE': return ContentType.INITIATIVE_SIZE;
        case 'STATUS': return ContentType.INITIATIVE_STATUS;
        case 'USER': return ContentType.OWNER;
        case 'DATE':
        case 'LIVE_DATE':
        case 'END_DATE':
        case 'START_DATE': return ContentType.DATE;
        case 'JIRA_LINK': return ContentType.JIRA_LINK;
        case 'PRODUCT': return ContentType.PRODUCT;
        case 'TEAM': return ContentType.TEAM;
        default: return ContentType.TEXT;
    }
}

const mapSectionToIcon = (key: string) => {
    switch (key) {
        case 'overview': return BroadcastIcon;
        case 'problem': return BugIcon;
        case 'validation': return CheckCircleIconCustom;
        case 'solution': return CircleWavyCheckIcon;
        case 'impact': return Crosshair;
        case 'requirements': return ChatCenteredDotsIcon;
        case 'mockups': return PaintBrushBroadIcon;
        case 'resources': return FileText;
        default: return null;
    }
}

export const mainSections = ['overview', 'problem', 'validation', 'solution', 'impact', 'requirements', 'mockups', 'resources'];

const formatPayloadValue = (type: string, value: any) => {
    if (type === 'DATE') {
        return new Date(value).getTime();
    }
    return value;
}

export function flattenInitiative(initiative: Record<string, any>) {
    return Object.values(initiative).reduce((result, section) => {
        if (Array.isArray(section)) {
            section.forEach((field: InitiativeField) => {
                result[field.fieldKey] = field.value;
            });
        }
        return result;
    }, {} as Record<string, any>);
}

interface InitiativeState {
    lastEditedDate?: string;
    initiativesV2: any[];
    currentInitiative: Record<string, any> | null,
    editMode: boolean,
    title: string,
    insightsOpen: boolean,
    isContentMinimized: boolean,
    initiativeTemplates: any[],
    generateInitiativePrompt: string,
    selectedTemplate: InitiativeTemplateV2 | null,
    sectionsToShow: { title: string, id: string, icon: (() => JSX.Element) | null, loading?: boolean }[],
    messages: Message[],
    isLoading: {
        templates: boolean,
        assistant: boolean,
    },
    assistantLogs: string[],
    assistantStreamingText: string,
    sessionId: string | null,
    sectionToRefine: {
        key: string,
        content: any,
        type: 'generate' | 'refine',
    } | null,
    unsavedChanges: boolean,
    dragKey: number,
    removingAttachmentIds: number[],
    blocks: Block<AttachmentMetadata>[],
    customSectionInteraction: boolean,
}

const initialState: InitiativeState = {
    initiativeTemplates: [],
    initiativesV2: [],
    generateInitiativePrompt: '',
    selectedTemplate: null,
    title: 'Untitled Initiative',
    sectionsToShow: [],
    editMode: false,
    insightsOpen: false,
    isContentMinimized: false,
    currentInitiative: null,
    isLoading: {
        templates: false,
        assistant: false,
    },
    assistantLogs: [],
    assistantStreamingText: '',
    messages: [],
    sessionId: null,
    sectionToRefine: null,
    unsavedChanges: false,
    dragKey: 0,
    removingAttachmentIds: [],
    blocks: [],
    customSectionInteraction: false,
}

const initiativeSlice = createSlice({
    name: 'initiative',
    initialState,
    reducers: {
        changeOverviewField: (state, action: PayloadAction<{ field: string, value: string | number | null }>) => {
            const { field, value } = action.payload;

            if (state.currentInitiative) {
                state.currentInitiative.overview = state.currentInitiative.overview.map((item: any) => {
                    if (item.fieldKey === field) {
                        item.value = value;
                    }
                    return item;
                })
            }
            state.unsavedChanges = true;
        },
        setEditMode: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        setInsightsOpen: (state, action: PayloadAction<boolean>) => {
            state.insightsOpen = action.payload;
        },
        setContentMinimized: (state, action: PayloadAction<boolean>) => {
            state.isContentMinimized = action.payload;
        },
        changeMainSimpleTextContentField: (state, action: PayloadAction<{ field: string, value: string }>) => {
            const { field, value } = action.payload;

            if (state.currentInitiative) {
                state.currentInitiative[field][0].value = value;
            }
            state.unsavedChanges = true;
        },
        changeRequirementField: (state, action: PayloadAction<{ field_generation_id: number, field: string, value: string }>) => {
            const { field_generation_id, field, value } = action.payload;
            const requirements = state.currentInitiative?.requirements[0].value;
            const requirement = requirements.find((requirement: any) => requirement.field_generation_id === field_generation_id);
            if (requirement) {
                requirement.content[field] = value;
            }
            state.unsavedChanges = true;
        },
        setRequirementItems: (state, action: PayloadAction<any[]>) => {
            if (state.currentInitiative) {
                state.currentInitiative.requirements[0].value = action.payload;
            }
            state.unsavedChanges = true;
        },
        addRequirement: (state, action: PayloadAction<any>) => {
            if (state.currentInitiative) {
                state.currentInitiative.requirements[0].value.unshift(action.payload);
            }
            state.unsavedChanges = true;
        },
        setGenerateInitiativePrompt: (state, action: PayloadAction<string>) => {
            state.generateInitiativePrompt = action.payload;
        },
        setSelectedTemplate: (state, action: PayloadAction<any>) => {
            state.selectedTemplate = action.payload;
        },
        setMessages: (state, action: PayloadAction<any[]>) => {
            state.messages = action.payload;
        },
        addAssistantLog: (state, action: PayloadAction<string>) => {
            state.assistantLogs = [...state.assistantLogs, action.payload];
        },
        setAssistantLogs: (state, action: PayloadAction<string[]>) => {
            state.assistantLogs = action.payload;
        },
        setAssistantStreamingText: (state, action: PayloadAction<any>) => {
            state.assistantStreamingText = action.payload;
        },
        setIsLoadingAssistant: (state, action: PayloadAction<boolean>) => {
            state.isLoading.assistant = action.payload;
        },
        setSessionId: (state, action: PayloadAction<string | null>) => {
            state.sessionId = action.payload;
        },
        resetInitiativeState: (state) => {
            state.currentInitiative = null;
            state.editMode = false;
            state.insightsOpen = false;
            state.isContentMinimized = false;
            state.messages = [];
            state.assistantLogs = [];
            state.assistantStreamingText = '';
            state.sessionId = null;
            state.title = 'Untitled Initiative';
            state.removingAttachmentIds = [];
        },
        deleteRequirement: (state, action: PayloadAction<number>) => {
            if (state.currentInitiative) {
                state.currentInitiative.requirements[0].value = state.currentInitiative.requirements[0].value.filter((requirement: any) => requirement.field_generation_id !== action.payload);
            }
            state.unsavedChanges = true;
        },
        changeCustomSectionField: (state, action: PayloadAction<{ sectionKey: string, fieldKey: string, value: any }>) => {
            if (state.currentInitiative) {
                state.currentInitiative[action.payload.sectionKey][0].value[action.payload.fieldKey] = action.payload.value;
                // TODO: We should not do this, we should get the title from the section
                if (action.payload.fieldKey === 'title') {
                    state.sectionsToShow = state.sectionsToShow.map((section: any) => {
                        if (section.id === action.payload.sectionKey) {
                            section.title = action.payload.value;
                        }
                        return section;
                    });
                }
            }
            state.unsavedChanges = true;
        },
        updateCustomSection: (state, action: PayloadAction<{ sectionKey: string, sectionData: any }>) => {
            if (state.currentInitiative) {
                state.currentInitiative[action.payload.sectionKey][0].value = action.payload.sectionData;
            }
            state.unsavedChanges = true;
        },
        deleteCustomSection: (state, action: PayloadAction<string>) => {
            if (state.currentInitiative) {
                delete state.currentInitiative[action.payload];
                state.sectionsToShow = state.sectionsToShow.filter((section: any) => section.id !== action.payload);
            }
            state.unsavedChanges = true;
            state.customSectionInteraction = true;
        },
        reorderRequirements: (state, action: PayloadAction<{ sourceIndex: number, destinationIndex: number }>) => {
            const { sourceIndex, destinationIndex } = action.payload;
            const requirements = state.currentInitiative?.requirements[0].value;
            const [removed] = requirements.splice(sourceIndex, 1);
            requirements.splice(destinationIndex, 0, removed);
            if (state.currentInitiative) {
                state.currentInitiative.requirements[0].value = requirements;
            }
            state.unsavedChanges = true;
        },
        setSectionToRefine: (state, action: PayloadAction<any>) => {
            state.sectionToRefine = action.payload;
        },
        setOkrs: (state, action: PayloadAction<any[]>) => {
            if (state.currentInitiative) {
                state.currentInitiative.impact[0].value = action.payload;
            }
            state.unsavedChanges = true;
        },
        setOkrField: (state, action: PayloadAction<{ id: number, krId: number, field: string, value: string | number | boolean }>) => {
            if (state.currentInitiative) {
                state.currentInitiative.impact[0].value = state.currentInitiative.impact[0].value.map((item: any) => {
                    if (item.okrId === action.payload.id) {
                        return {
                            ...item,
                            contributions: item.contributions.map((cont: any) => {
                                if (cont.keyResult.id === action.payload.krId) {
                                    return {
                                        ...cont,
                                        [action.payload.field]: action.payload.value
                                    }
                                }
                                return cont
                            })
                        }
                    }
                    return item;
                })
            }
            state.unsavedChanges = true;
        },
        deleteInitiative: (state, action: PayloadAction<number>) => {
            state.initiativesV2 = state.initiativesV2.filter((initiative) => initiative.id !== action.payload);
        },
        setCurrentInitiative: (state, action: PayloadAction<{ initiative: any, template: InitiativeTemplateV2 }>) => {
            const initiative = action.payload.initiative ? action.payload.initiative : state.currentInitiative;
            const sections = action.payload.template.content.sections;
            state.title = initiative.title;
            state.sectionsToShow = sections.map((section: InitiativeTemplateSection) => ({
                // TODO: We should not do this, we should get the title from the section
                title: mainSections.includes(section.key) ? section.name : initiative?.fields[section.key]?.value?.title || 'Untitled Section',
                id: section.key,
                icon: mapSectionToIcon(section.key)
            }))
            state.sessionId = initiative.chatSessionId;
            state.currentInitiative = sections.reduce((acc: any, section: InitiativeTemplateSection) => {
                acc[section.key] = section.fields.map((field: InitiativeTemplateField) => {
                    const payloadField = initiative.fields[field.fieldKey];
                    return {
                        type: mapContentType(field.type),
                        displayName: field.displayName,
                        fieldKey: field.fieldKey,
                        options: payloadField?.config?.options || field.options,
                        outputSchema: payloadField?.config?.outputSchema || field.outputSchema,
                        uiConfig: payloadField?.config?.uiConfig || field.uiConfig,
                        isRequired: payloadField?.config?.isRequired ?? field.isRequired,
                        value: payloadField?.value !== undefined && payloadField.value !== null ? formatPayloadValue(field.type, payloadField.value)
                            : !mainSections.includes(section.key) && payloadField.config ? arrayToObject(payloadField.config.outputSchema.required)
                                : (field.uiConfig.elementType === 'resource_block' ||
                                    field.uiConfig.elementType === 'requirement_block' ||
                                    field.uiConfig.elementType === 'okr_block' ||
                                    field.fieldKey === 'mockup') ? []
                                    : field.defaultValue
                    };
                });
                return acc;
            }, {} as Record<string, InitiativeField>);
        },
        setResources: (state, action: PayloadAction<any>) => {
            if (state.currentInitiative) {
                state.currentInitiative.resources[0].value = action.payload;
            }
            state.unsavedChanges = true;
        },
        setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.unsavedChanges = action.payload;
        },
        setDragKey: (state, action: PayloadAction<number>) => {
            state.dragKey = action.payload;
        },
        setRemovingAttachmentIds: (state, action: PayloadAction<number[]>) => {
            state.removingAttachmentIds.push(...action.payload);
        },
        setMockups: (state, action: PayloadAction<any>) => {
            if (state.currentInitiative) {
                state.currentInitiative.mockups[0].value = action.payload;
            }
            state.unsavedChanges = true;
        },
        setBlocks: (state, action: PayloadAction<Block<AttachmentMetadata>[]>) => {
            state.blocks = action.payload;
        },
        addCustomSection: (state, action: PayloadAction<{ sectionKey: string, sectionData: any }>) => {
            if (state.currentInitiative) {
                state.currentInitiative[action.payload.sectionKey] = action.payload.sectionData;
            }
            state.unsavedChanges = true;
        },
        addNewCustomSection: (state, action: PayloadAction<{ title: string, id: string, icon: (() => JSX.Element) | null }>) => {
            state.sectionsToShow = [...state.sectionsToShow, action.payload];
            state.customSectionInteraction = true;
        },
        setCustomSectionInteraction: (state, action: PayloadAction<boolean>) => {
            state.customSectionInteraction = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                initiativeApi.endpoints.getInitiativeTemplates.matchPending,
                (state) => {
                    state.isLoading.templates = true;
                }
            )
            .addMatcher(
                initiativeApi.endpoints.getInitiativeTemplates.matchFulfilled,
                (state, action: PayloadAction<InitiativeTemplateV2[]>) => {
                    state.initiativeTemplates = action.payload;
                    state.isLoading.templates = false;
                    state.selectedTemplate = action.payload[0]
                },
            )
            .addMatcher(
                initiativeApi.endpoints.getInitiativesV2.matchFulfilled,
                (state, action: PayloadAction<Initiative[]>) => {
                    state.initiativesV2 = action.payload;
                }
            )
            .addMatcher(
                initiativeApi.endpoints.getUserSession.matchFulfilled,
                (state, action: PayloadAction<any>) => {
                    if (action.payload.messages.length > 0) {
                        state.messages = action.payload.messages.map((message: any) => ({
                            from: message.from,
                            references: message.references,
                            id: message.id,
                            stream_plan: message.stream_plan?.map((plan: GenerationPlan) => ({
                                ...plan,
                                finish: true
                            })),
                            stream_logs: message.stream_logs,
                            ...message.message
                        }));
                    }
                }
            )
    },
})

export const initiativeTitleSelector = (store: RootState) => store.initiative.currentInitiative?.overview.find((item: any) => item.fieldKey === 'title')?.value;
export const editModeSelector = (store: RootState) => store.initiative.editMode;
export const insightsOpenSelector = (store: RootState) => store.initiative.insightsOpen;
export const isContentMinimizedSelector = (store: RootState) => store.initiative.isContentMinimized;
export const lastEditedDateSelector = (store: RootState) => store.initiative.lastEditedDate;
export const overviewSelector = (store: RootState) => store.initiative.currentInitiative?.overview;
export const liveDateSelector = (store: RootState) => store.initiative.currentInitiative?.overview.find((item: any) => item.fieldKey === 'liveDate')?.value;
export const endDateSelector = (store: RootState) => store.initiative.currentInitiative?.overview.find((item: any) => item.fieldKey === 'endDate')?.value;
export const startDateSelector = (store: RootState) => store.initiative.currentInitiative?.overview.find((item: any) => item.fieldKey === 'startDate')?.value;
export const problemSelector = (store: RootState) => store.initiative.currentInitiative?.problem[0].value;
export const solutionSelector = (store: RootState) => store.initiative.currentInitiative?.solution[0].value;
export const validationSelector = (store: RootState) => store.initiative.currentInitiative?.validation[0].value;
export const requirementsSelector = (store: RootState) => store.initiative.currentInitiative?.requirements[0].value;
export const initiativeTemplatesSelector = (store: RootState) => store.initiative.initiativeTemplates;
export const generateInitiativePromptSelector = (store: RootState) => store.initiative.generateInitiativePrompt;
export const selectedTemplateSelector = (store: RootState) => store.initiative.selectedTemplate;
export const sectionsToShowSelector = (store: RootState) => store.initiative.sectionsToShow;
export const isLoadingTemplatesSelector = (store: RootState) => store.initiative.isLoading.templates;
export const okrsSelector = (store: RootState) => store.initiative.currentInitiative?.impact[0].value;
export const mockupsSelector = (store: RootState) => store.initiative.currentInitiative?.mockups[0].value;
export const resourcesSelector = (store: RootState) => store.initiative.currentInitiative?.resources[0].value;
export const messagesSelector = (store: RootState) => store.initiative.messages;
export const assistantLogsSelector = (store: RootState) => store.initiative.assistantLogs;
export const assistantStreamingTextSelector = (store: RootState) => store.initiative.assistantStreamingText;
export const isLoadingAssistantSelector = (store: RootState) => store.initiative.isLoading.assistant;
export const sessionIdSelector = (store: RootState) => store.initiative.sessionId;
export const sectionToRefineSelector = (store: RootState) => store.initiative.sectionToRefine;
export const currentInitiativeSelector = (store: RootState) => store.initiative.currentInitiative;
export const initiativesV2Selector = (store: RootState) => store.initiative.initiativesV2;
export const unsavedChangesSelector = (store: RootState) => store.initiative.unsavedChanges;
export const dragKeySelector = (store: RootState) => store.initiative.dragKey;
export const removingAttachmentIdsSelector = (store: RootState) => store.initiative.removingAttachmentIds;
export const blocksSelector = (store: RootState) => store.initiative.blocks;
export const customSectionInteractionSelector = (store: RootState) => store.initiative.customSectionInteraction;

export const {
    setEditMode,
    setInsightsOpen,
    setContentMinimized,
    changeMainSimpleTextContentField,
    changeRequirementField,
    setGenerateInitiativePrompt,
    setSelectedTemplate,
    setMessages,
    setAssistantLogs,
    setAssistantStreamingText,
    setIsLoadingAssistant,
    setRequirementItems,
    setSessionId,
    resetInitiativeState,
    addRequirement,
    deleteRequirement,
    reorderRequirements,
    setSectionToRefine,
    setOkrs,
    changeOverviewField,
    deleteInitiative,
    setCurrentInitiative,
    setResources,
    addAssistantLog,
    setOkrField,
    setUnsavedChanges,
    setDragKey,
    setRemovingAttachmentIds,
    setMockups,
    setBlocks,
    changeCustomSectionField,
    deleteCustomSection,
    addCustomSection,
    updateCustomSection,
    addNewCustomSection,
    setCustomSectionInteraction
} = initiativeSlice.actions

export default initiativeSlice;