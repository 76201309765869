import { Button, ConfirmationDialog, FilterButton, Flexbox, Loader, SearchField, Snackbar, Tooltip } from 'components';
import { GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import AgGridTable, { AgColumn, getLinkedColumnContextMenuItems, ColumnTypes, GridStatePreferences } from 'components/AgGridTable';
import { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getEnumKeyByEnumValue } from 'utils';
import { Actions, hasPermission } from 'utils/permissions';
import { compareEnumValues } from 'utils/tableSorting';
import { CalendarPresetsKeys, InitiativePriority, InitiativeStatus, Preferences, PreferenceValues } from 'utils/types';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import { GenerateIcon } from 'components/icons';
import { initiativesV2Selector, deleteInitiative } from 'store/initiative-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteInitiativeV2Mutation, useLazyGetInitiativesV2Query } from 'store/initiative-api';
import { useDebounce, useWorkspaceId } from 'utils/hooks';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import ActionsButton from 'components/ActionsButton';
import GeneratePRDPopup from 'pages/InitiativeManagement/GeneratePRDPopup';
import PRDGenerationContent from 'pages/InitiativeManagement/GeneratePRDPopup/PRDGenerationContent';
import { FilterOption } from 'components/FilterButton';
import { FilterValuesReducerAction } from 'pages/Products/productsList';
// import { formatDate, getQuarterEndDate } from 'utils/date';
// import { getQuarterStartDate } from 'utils/date';
import { Preset } from 'components/Datepicker';
import { PreferencesKeys } from 'utils/types';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import { useLazyGetUsersQuery } from 'store/users-api';
import { userSelector } from 'store/user';
import { activeUsersSelector } from 'store/users-slice';
import EmptyView from 'pages/InitiativeManagement/EmptyView';

const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);

export enum FilterKeys {
    owner = 'owner',
    status = 'status',
    priority = 'priority',
    query = 'query',
    orderBy = 'orderBy',
    order = 'order',
    startDate = 'startDate',
    liveDate = 'liveDate',
    calendarSelect = 'calendarSelect',
    initiativeV2GridLayout = 'initiativeV2GridLayout',
}

const defaultFilterState = {
    [FilterKeys.owner]: [],
    [FilterKeys.status]: [],
    [FilterKeys.priority]: [],
}

const filterValuesReducer = (state: { [key: string]: FilterOption[] }, action: FilterValuesReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return defaultFilterState;
        default:
            return state;
    }
}


const InitiativesV2 = () => {
    const navigate = useNavigate();
    const workspaceId = useWorkspaceId();
    const dispatch = useDispatch();

    const [getInitiativesV2] = useLazyGetInitiativesV2Query();
    const [deleteInitiativeV2, { isLoading: isDeletingInitiative }] = useDeleteInitiativeV2Mutation();
    const [getUsers] = useLazyGetUsersQuery()

    const initiatives = useSelector(initiativesV2Selector);
    const user = useSelector(userSelector);
    const users = useSelector(activeUsersSelector);

    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null)
    const [openGeneratePRDPopup, setOpenGeneratePRDPopup] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [initiativeId, setInitiativeId] = useState<number | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState<string | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();
    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);
    const [userOptions, setUserOptions] = useState<FilterOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<FilterOption[]>(Object.keys(InitiativeStatus).map((key, index) => ({ id: index, title: key })));
    const [priorityOptions, setPriorityOptions] = useState<FilterOption[]>(Object.keys(InitiativePriority).map((key, index) => ({ id: index, title: key })));
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any[]>([]);

    const [dateFrom, setDateFrom] = useState<Date | null>();
    const [dateTo, setDateTo] = useState<Date | null>();

    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [gridStatePreferences, setGridStatePreferences] = useState<GridStatePreferences | undefined>({})

    const [calendarSelect, setCalendarSelect] = useState<string | undefined>()
    const [presets, setPresets] = useState<Preset[]>([
        { id: CalendarPresetsKeys.lastQuarter, title: 'Last quarter' },
        { id: CalendarPresetsKeys.lastMonth, title: 'Last month' },
        { id: CalendarPresetsKeys.thisMonth, title: 'This month' },
        { id: CalendarPresetsKeys.nextMonth, title: 'Next month' },
        { id: CalendarPresetsKeys.thisQuarter, title: 'This quarter' },
        { id: CalendarPresetsKeys.nextQuarter, title: 'Next quarter' },
        { id: CalendarPresetsKeys.thisYear, title: 'This year' },
        { id: CalendarPresetsKeys.nextYear, title: 'Next year' },
    ])
    const [searchParams, setSearchParams] = useSearchParams();

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.initiativeV2))) as unknown as Preferences<FilterKeys>[];
        if (preferences && preferences.length) {

            const { initiativeV2GridLayout, order, orderBy, ...filters } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            initiativeV2GridLayout && setGridStatePreferences(initiativeV2GridLayout as GridStatePreferences);
            setOrder(order);
            setOrderBy(orderBy)

            if (searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true })
            }
        }
    }

    useEffect(() => {
        const options: FilterOption[] = users.map(u => ({ id: u.id, title: `${u.fullName}${u.id === user.id ? ' (Me)' : ''}`, tooltip: u.email }))
        const currentUserIndex = options.findIndex((o) => o.id === user.id)
        if (currentUserIndex >= 0) {
            const currentUserOption = options.splice(currentUserIndex, 1)[0]
            options.splice(0, 0, currentUserOption)
        }
        setUserOptions(options)

    }, [users, user])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            await Promise.all([
                getInitiativesV2({ workspaceId }),
                getUsers({ workspaceId }),
                loadPreferences()
            ])
            setIsLoading(false)
        }
        fetchData();
    }, []);

    const columns: AgColumn[] = useMemo(() => {
        const columns: AgColumn[] = [
            {
                colType: ColumnTypes.Linked,
                headerName: 'Title',
                field: 'title',
                defaultValue: 'Untitled',
                minWidth: 250,
                sortable: true,
                link: '/initiativesV2/initiativeV2',
                linkParam: 'id',
                wrapText: true,
                autoHeight: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.title : '';
                },
            },
            {
                colType: ColumnTypes.SimpleSelect,
                headerName: 'Owner',
                field: 'owner',
                minWidth: 200,
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.owner?.fullName : '';
                },
            },
            {
                colType: ColumnTypes.Priority,
                headerName: 'Priority',
                field: 'priority',
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.priority : '';
                },
            },
            {
                headerName: 'Size',
                field: 'size',
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.size : '';
                },
            },
            {
                headerName: 'Type',
                field: 'type',
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.type : '';
                },
            },
            {
                colType: ColumnTypes.SimpleSelect,
                headerName: 'Status',
                field: 'status',
                minWidth: 180,
                sortable: true,
                comparator: (d1: any, d2: any) => {
                    return compareEnumValues(d1, d2, InitiativeStatus)
                },
                valueFormatter: (params: any) => {
                    return getEnumKeyByEnumValue(InitiativeStatus, params.value) || ''
                },
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.status : '';
                },
            },
            {
                colType: ColumnTypes.SimpleSelect,
                headerName: 'Product',
                field: 'product',
                minWidth: 180,
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.product?.title : '';
                },
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Development Start Date',
                field: 'startDate',
                minWidth: 120,
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.startDate : '';
                },
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Development End Date',
                field: 'endDate',
                minWidth: 120,
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.endDate : '';
                },
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Live Date',
                field: 'liveDate',
                minWidth: 120,
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.fields?.liveDate : '';
                },
            },
            {
                colType: ColumnTypes.Action,
                field: 'actions',
                headerName: '',
                actions: (params: any) => {
                    return [
                        {
                            label: 'Open',
                            action: () => {
                                navigate(`/initiativesV2/initiativeV2/${params?.node?.data?.id}`)
                            },
                        },
                        ...(hasPermission(Actions.delete, params.data) ? [
                            { label: 'Delete', action: () => showDeleteConfirmation(params.data.id), type: 'red' }
                        ] : []),
                    ]
                },
            },
        ]
        return columns;
    }, [])

    const deleteInitiativeAction = async () => {
        if (initiativeId) {
            const response = await deleteInitiativeV2({ workspaceId, initiativeId });

            if ('data' in response) {
                dispatch(deleteInitiative(initiativeId));
                setSuccessMessage('Initiative deleted successfully');
                onCancelDelete();
            }
        }
    }

    const showDeleteConfirmation = (idInitiativeItem: number) => {
        setInitiativeId(idInitiativeItem)
        setOpenConfirmation(true)
    }
    const onGeneratePRD = () => {
        setOpenGeneratePRDPopup(true)
    }

    const onCancelDelete = () => {
        setOpenConfirmation(false)
    }

    const closeGeneratePRDPopup = () => {
        setOpenGeneratePRDPopup(false)
    }


    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }

    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }


    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value })
        setCanUpdatePreferences(true)
    }

    const resetAllFilter = () => {
        setSearchValue('');
        setOrder(undefined);
        setOrderBy(undefined);
        setFilterValues({ type: 'reset' })
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] })
        setCanUpdatePreferences(true)
    }

    const onDateChange = ([startDate, dateTo]: [Date | null, Date | null]) => {
        setCalendarSelect(undefined)
        setDateFrom(startDate);
        setDateTo(dateTo);
        if (dateTo) {
            setCanUpdatePreferences(true)
        }
    };

    const selectCalendarRange = (id: string) => {
        setCalendarSelect(id)
        setCanUpdatePreferences(true)
    }

    useEffect(() => {
        if (canUpdatePreferences) {
            const ownerIds = filterValues[FilterKeys.owner].map(filter => filter.id);
            const statuses = filterValues[FilterKeys.status].map(filter => filter.title);
            const priorities = filterValues[FilterKeys.priority].map(filter => filter.title);

            const filterKeys: PreferenceValues<FilterKeys> = {};

            if (ownerIds.length) {
                filterKeys[FilterKeys.owner] = ownerIds.join(',');
            }

            if (statuses.length) {
                filterKeys[FilterKeys.status] = statuses.join(',');
            }
            if (priorities.length) {
                filterKeys[FilterKeys.priority] = priorities.join(',');
            }

            if (searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }
            if (order && orderBy) {
                filterKeys[FilterKeys.order] = order;
                filterKeys[FilterKeys.orderBy] = orderBy;
            }

            // if (dateFrom && dateTo && calendarSelect === undefined) {
            //     filterKeys[FilterKeys.startDate] = dateFrom.toUTCString();
            //     filterKeys[FilterKeys.liveDate] = dateTo.toUTCString();
            // }

            if (calendarSelect) {
                filterKeys[FilterKeys.calendarSelect] = calendarSelect
            }

            dispatch(updatePreferences({ ...filterKeys, initiativeV2GridLayout: gridStatePreferences }, PreferencesKeys.initiativeV2));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [filterValues, searchDebounceValue, order, orderBy, dateTo]);

    useEffect(() => {

        if (!isLoading) {
            const ownerIdsString = searchParams.get(FilterKeys.owner);
            if (ownerIdsString) {
                const ownerIds = ownerIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.owner, payload: userOptions.filter(option => ownerIds.includes(option.id)) })
            }

            const statusesString = searchParams.get(FilterKeys.status);
            if (statusesString) {
                const statuses = statusesString.split(',');
                setFilterValues({ type: 'update', key: FilterKeys.status, payload: statusOptions.filter(option => statuses.includes(option.title)) })
            }

            const prioritiesString = searchParams.get(FilterKeys.priority);
            if (prioritiesString) {
                const priorities = prioritiesString.split(',');
                setFilterValues({ type: 'update', key: FilterKeys.priority, payload: priorityOptions.filter(option => priorities.includes(option.title)) })
            }

            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }

            const startDate = searchParams.get(FilterKeys.startDate);
            if (startDate) {
                setDateFrom(new Date(startDate));
            }

            const liveDate = searchParams.get(FilterKeys.liveDate);
            if (liveDate) {
                setDateTo(new Date(liveDate));
            }

            const presetType = searchParams.get(FilterKeys.calendarSelect)
            if (presetType) {
                setCalendarSelect(presetType)
            }
        }
    }, [isLoading])

    const onSortChanged = (e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (order !== value?.sort || orderBy !== value?.colId) {
            modifiedSearchParamsObject.initiativeV2GridLayout = gridStatePreferences
            dispatch(updatePreferences(modifiedSearchParamsObject, PreferencesKeys.initiativeV2));
        }
    }

    const onGridStateChanged = (data: GridStatePreferences) => {
        setGridStatePreferences(data)
        const updatedPreferences: any = {};

        searchParams.forEach((value, key) => {
            updatedPreferences[key] = value;
        });

        updatedPreferences.initiativeV2GridLayout = data

        dispatch(updatePreferences(updatedPreferences, PreferencesKeys.initiativeV2));
    }

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    useEffect(() => {
        let data = [...initiatives];

        if (searchDebounceValue) {
            data = data.filter(initiative => initiative.fields.title && initiative.fields.title.toLowerCase().includes(searchDebounceValue.toLowerCase()))
        }

        data = data.filter(initiative => {
            if (!filterValues[FilterKeys.owner].length || filterValues[FilterKeys.owner].some(filter => filter.id === initiative.fields.owner?.id)) {
                return true;
            }
            return false;
        })

        data = data.filter(initiative => {
            const initiativeStatus = getEnumKeyByEnumValue(InitiativeStatus, initiative.fields.status);
            if (!filterValues[FilterKeys.status].length || filterValues[FilterKeys.status].some(filter => filter.title === initiativeStatus)) {
                return true;
            }
            return false
        })

        data = data.filter(initiative => {
            const initiativePriority = getEnumKeyByEnumValue(InitiativePriority, initiative.fields.priority);
            if (!filterValues[FilterKeys.priority].length || filterValues[FilterKeys.priority].some(filter => filter.title === initiativePriority)) {
                return true;
            }
            return false
        })


        data = calendarFilter(data)

        setData(data)
    }, [initiatives, order, orderBy, searchDebounceValue, filterValues, dateFrom, dateTo]);

    const calendarFilter = (data: any[]) => {
        if (dateFrom && dateTo) {
            const newData = data.filter(initiative => {
                const startDate = initiative.startDate && new Date(initiative.startDate)
                const releaseDate = initiative.releaseDate && new Date(initiative.releaseDate);

                if ((startDate && startDate >= dateFrom && startDate <= dateTo) || (releaseDate && releaseDate >= dateFrom && releaseDate <= dateTo) || (startDate && startDate <= dateFrom && releaseDate && releaseDate >= dateTo)) {
                    return true
                }
                return false
            })
            return newData
        }
        return data
    }

    return (
        <Flexbox vertical fullWidth className={classes('mainContainer')}>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>PRDs / Initiatives V2</Flexbox>
                    {initiatives.length > 0 &&
                        <Flexbox>
                            {gridApi && (
                                <ExportButton
                                    api={gridApi}
                                    formatExportedCellValue={
                                        (colId: string, value: any, formattedValue: any) => {
                                            if ((colId === 'liveDate' || colId === 'startDate' || colId === 'endDate')) {
                                                return value ? new Date(value).toLocaleDateString() : '';
                                            } else {
                                                return formattedValue
                                            }
                                        }
                                    }
                                />
                            )}
                            {hasPermission(Actions.create) &&
                                <ActionsButton
                                    buttonItems={[
                                        { label: 'Generate Initiative Beta', action: onGeneratePRD, icon: <GenerateIcon /> },
                                    ]}
                                    className={classes('actionMenu')}
                                />
                            }
                        </Flexbox>
                    }
                </Flexbox>
                {initiatives.length > 0 &&
                    <Flexbox className={classesInfo('headerInfo')}>
                        <Flexbox>
                            <SearchField
                                value={searchValue}
                                onChange={onSearchValueChange}
                                onClear={onSearchClear}
                                placeholder='Search Initiative'
                                className={classesInfo('searchInput')}
                            />
                            <FilterButton
                                options={userOptions}
                                value={filterValues[FilterKeys.owner]}
                                onChange={(value) => onFilterValueChange(FilterKeys.owner, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.owner)}
                                multiple
                                label={'Owner'}
                                className={classesInfo('filterButton')}
                                keepFirstOption
                            />
                            <FilterButton
                                options={statusOptions}
                                value={filterValues[FilterKeys.status]}
                                onChange={(value) => onFilterValueChange(FilterKeys.status, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.status)}
                                multiple
                                sortAlphabetically={false}
                                label={'Status'}
                                className={classesInfo('filterButton')}
                            />
                            <FilterButton
                                options={priorityOptions}
                                value={filterValues[FilterKeys.priority]}
                                onChange={(value) => onFilterValueChange(FilterKeys.priority, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.priority)}
                                multiple
                                sortAlphabetically={false}
                                label={'Priority'}
                                className={classesInfo('filterButton')}
                            />
                            {Object.values(filterValues).some(v => v.length !== 0) &&
                                <Flexbox className={classesInfo('resetButtonContainer')}>
                                    <Button className={classesInfo('resetButton')} onClick={resetAllFilter}>
                                        Reset
                                    </Button>
                                </Flexbox>
                            }
                        </Flexbox>
                        {/* <Flexbox className={classes('datePickerBox')}>
                            <DatePicker
                                monthsShown={2}
                                selectsRange
                                onChange={onDateChange}
                                startDate={dateFrom}
                                endDate={dateTo}
                                presets={{
                                    onPresetSelect: selectCalendarRange,
                                    presets,
                                }}
                                type='button'
                                dateFormat="MM.dd.yy"
                                placeholderText='mm.dd.yy'
                            />
                        </Flexbox> */}
                    </Flexbox>
                }
            </Flexbox>
            <Flexbox className={classes('tableContainer')}>
                {isLoading ? (
                    <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>
                ) : initiatives.length > 0 ? (
                    <AgGridTable
                        data={data}
                        columns={columns}
                        getContextMenuItems={params => {
                            if (params.defaultItems) {
                                const rowData = params?.node?.data;
                                const url = `/initiatives/initiative/${rowData.id}`;

                                const linkItems = getLinkedColumnContextMenuItems(params, url, 'title')
                                const menuItems = [
                                    ...params.defaultItems,
                                    ...linkItems,
                                ]

                                return menuItems
                            } else {
                                return []
                            }
                        }}
                        order={order}
                        orderBy={orderBy}
                        onSortChanged={onSortChanged}
                        onGridStateChanged={onGridStateChanged}
                        gridStatePreferences={gridStatePreferences}
                        onGridReady={onGridReady}
                        exportFileName='Initiatives'
                    />
                ) : (
                    <Flexbox fullWidth>
                        <EmptyView
                            title=""
                            description=""
                            showDefaultIcon={false}
                            content={<PRDGenerationContent />}
                        />
                    </Flexbox>
                )}
            </Flexbox>
            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={deleteInitiativeAction}
                confirmButtonStyle='danger'
                title='Delete this initiative?'
            >
                <Flexbox>
                    You're about to permanently delete this initiative, and all connected data will be lost.
                </Flexbox>
            </ConfirmationDialog>

            {openGeneratePRDPopup && <GeneratePRDPopup open={openGeneratePRDPopup} onClose={closeGeneratePRDPopup} />}
            {successMessage && <Snackbar
                onClose={() => setSuccessMessage(undefined)}
                open={!!successMessage}
                message={successMessage}
                type='success'
            >
                <Flexbox>
                    {successMessage}
                </Flexbox>
            </Snackbar>
            }
        </Flexbox>
    )
}

export default InitiativesV2;