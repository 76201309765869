import { CommonButton, Flexbox, Typography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { AIStarsIcon, InitiativeEmptyStateIcon } from 'components/icons';
import { FC, ReactNode } from 'react';

const classes = classNames.bind(styles);

interface EmptyViewProps {
    title: string;
    description: string;
    aiActionText?: string;
    manualActionText?: string;
    onAIAction?: () => void;
    onManualAction?: () => void;
    customActions?: React.ReactNode;
    content?: ReactNode;
    icon?: ReactNode;
    showDefaultIcon?: boolean;
}

const EmptyView: FC<EmptyViewProps> = ({
    title,
    description,
    aiActionText,
    manualActionText,
    onAIAction,
    onManualAction,
    customActions,
    content,
    icon,
    showDefaultIcon = true
}) => {

    return (
        <Flexbox vertical justify align className={classes('empty-view')}>
            <Flexbox vertical justify align className={classes('empty-view-content')}>
                {content ? (
                    content
                ) : (
                    <>
                        {icon !== null && (icon || (showDefaultIcon && <InitiativeEmptyStateIcon />))}
                        <Flexbox vertical justify align className={classes('gap-6')}>
                            <Flexbox vertical justify align className={classes('gap-2')}>
                                <Typography className={classes('title', 'paragraph-16-regular')}>{title}</Typography>
                                <Typography className={classes('description', 'paragraph-15-regular')}>{description}</Typography>
                            </Flexbox>
                            <Flexbox justifyBetween align vertical className={classes('gap-4')}>
                                {aiActionText && (
                                    <CommonButton
                                        buttonType='shadow'
                                        buttonSize='small'
                                        startIcon={<AIStarsIcon />}
                                        onClick={onAIAction}
                                    >
                                        {aiActionText}
                                    </CommonButton>
                                )}
                                {manualActionText && (
                                    <Flexbox vertical justify align className={classes('manual-action')} onClick={onManualAction}>
                                        <Typography className={classes('manual-action-text', 'paragraph-15-regular')} >{manualActionText}</Typography>
                                    </Flexbox>
                                )}
                                {customActions}
                            </Flexbox>
                        </Flexbox>
                    </>
                )}
            </Flexbox>
        </Flexbox>
    )
}

export default EmptyView;