import { FC, useState } from 'react'
import ContentSectionLayout from '../ContentSectionLayout'
import { ChatCenteredDotsIcon, PlusIcon } from 'components/icons'
import { Flexbox, IconButtonComponent, Typography, CommonButton } from 'components'
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Requirement from './Requirement'
import EmptyView from '../EmptyView'
import { useSelector, useDispatch } from 'react-redux'
import { requirementsSelector, editModeSelector, addRequirement, setEditMode, reorderRequirements, setSectionToRefine, setDragKey, dragKeySelector } from 'store/initiative-slice'
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

const classes = classNames.bind(styles);

// Custom empty view without icon and title
const RequirementsEmptyView: FC<{ description: string }> = ({ description }) => {
    return (
        <EmptyView
            title=""
            description={description}
            showDefaultIcon={false}
        />
    );
};

interface RequirementsProps {

}

const Requirements: FC<RequirementsProps> = () => {
    const dispatch = useDispatch();
    const [isDragging, setIsDragging] = useState(false);
    const dragKey = useSelector(dragKeySelector);

    const requirements = useSelector(requirementsSelector);
    const editMode = useSelector(editModeSelector);

    const handleAddNewRequirement = () => {
        if (!editMode) {
            dispatch(setEditMode(true));
        }

        dispatch(addRequirement({
            field_generation_id: uuidv4(),
            content: {
                title: '',
                description: '',
            }
        }));
    }
    const handleDragStart = () => {
        setIsDragging(true);
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            setIsDragging(false);
            return;
        }

        dispatch(reorderRequirements({
            sourceIndex: result.source.index,
            destinationIndex: result.destination.index
        }));

        setTimeout(() => {
            setIsDragging(false);
            dispatch(setDragKey(dragKey + 1));
        }, 0);
    };

    return (
        <ContentSectionLayout
            title="Requirements"
            icon={<ChatCenteredDotsIcon />}
            aiInteractionText={'AI'}
            id='initiative-requirements'
            isEmpty={requirements.length === 0 && !editMode}
            dataSectionId='requirements'
            actions={
                editMode && (
                    <Flexbox align className={classes('actions-container', 'gap-1')} data-section-id='initiative-resources'>
                        <CommonButton
                            buttonType='shadow'
                            buttonSize='small'
                            startIcon={<PlusIcon />}
                            onClick={handleAddNewRequirement}
                        >
                        Add
                        </CommonButton>
                    </Flexbox>
                )
            }
            onAIAction={() => dispatch(setSectionToRefine({ key: 'requirements', type: requirements.length === 0 ? 'generate' : 'refine', content: requirements }))}
        >
            {(requirements.length === 0) ? (
                <RequirementsEmptyView
                    description='List the essential features, functionalities, and constraints needed for the solution.'
                />
            ) : (
                <DragDropContext
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <Droppable droppableId="requirements">
                        {(provided) => (
                            <Flexbox
                                vertical
                                className={classes('requirements-container', 'gap-1')}
                                data-section-id='requirements'
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {requirements.map((requirement: any, index: number) => (
                                    <Requirement
                                        key={requirement.field_generation_id}
                                        draggingKey={`${requirement.field_generation_id}-${dragKey}`}
                                        requirement={requirement}
                                        index={index}
                                        isDragging={isDragging}
                                    />
                                ))}
                                {provided.placeholder}
                            </Flexbox>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </ContentSectionLayout>
    )
}

export default Requirements;
