import { TextField, Select, MenuItem, InputAdornment } from '@mui/material';
import { useState, useRef, ChangeEvent, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Selector from '../Selector';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Flexbox, Typography } from 'components';
import Tag from 'components/Tag';
import { TagType, ContentType } from 'utils/types';
import { TickRoundedIcon } from 'components/icons';

const classes = classNames.bind(styles);

export interface Option {
    label: string;
    value: string | number;
    tooltip?: string
}

type DropdownPosition = 'left' | 'right' | 'center';

interface SearchableSelectProps {
    options: Option[];
    value?: string | number | null;
    onChange?: (value: Option | null) => void;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    className?: string;
    searchable?: boolean;
    labelStartIcon?: React.ReactNode;
    title?: string;
    type?: ContentType;
    checkable?: boolean;
    size?: 'medium' | 'large';
    dropdownType?: 'white' | 'filled' | 'outlined';
    componentType?: 'Dropdown' | 'Selector' | 'Button';
    onSelect?: (newValue: any) => void;
    isLoading?: boolean;
    multiSelect?: boolean;
    buttonText?: string;
    fullWidth?: boolean;
    position?: DropdownPosition;
}

const SearchableSelect = ({
    options,
    value: defaultValue,
    onChange,
    label,
    placeholder = 'Select...',
    disabled = false,
    error = false,
    helperText,
    required = false,
    className,
    labelStartIcon,
    searchable,
    title,
    type,
    checkable,
    size,
    dropdownType,
    componentType,
    onSelect,
    isLoading,
    multiSelect = false,
    buttonText,
    fullWidth = false,
    position = 'right'
}: SearchableSelectProps) => {

    const getMenuPosition = (position: DropdownPosition) => {
        switch (position) {
            case 'right':
                return {
                    anchorOrigin: {
                        vertical: 'bottom' as const,
                        horizontal: 'right' as const,
                    },
                    transformOrigin: {
                        vertical: 'top' as const,
                        horizontal: 'right' as const,
                    }
                };
            case 'center':
                return {
                    anchorOrigin: {
                        vertical: 'bottom' as const,
                        horizontal: 'center' as const,
                    },
                    transformOrigin: {
                        vertical: 'top' as const,
                        horizontal: 'center' as const,
                    }
                };
            case 'left':
            default:
                return {
                    anchorOrigin: {
                        vertical: 'bottom' as const,
                        horizontal: 'left' as const,
                    },
                    transformOrigin: {
                        vertical: 'top' as const,
                        horizontal: 'left' as const,
                    }
                };
        }
    };

    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState<(string | number)[]>(
        Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : []
    );

    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValue(Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : []);
    }, [defaultValue]);

    const filteredOptions = options.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    return (
        <Flexbox vertical fullWidth={fullWidth} className={classes('searchableSelectContainer')}>
            <Select
                multiple={multiSelect}
                value={value}
                className={classes('searchableSelect', className, {
                    'searchableSelect--open': isOpen
                })}
                disabled={disabled}
                error={error}
                required={required}
                displayEmpty
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => {
                    setIsOpen(false);
                    setSearchText('');
                }}
                renderValue={(selected) => {
                    if (!selected?.length) { return placeholder; }
                    return multiSelect ? (
                        <Flexbox align wrap justifyEnd className={classes('searchableSelect-tags', 'gap-1')}>
                            {options
                                .filter(opt => (selected as (string | number)[]).includes(opt.value))
                                .map(opt => (
                                    <Tag
                                        key={opt.value}
                                        label={opt.label}
                                        type={TagType.NATURAL}
                                    />
                                ))}
                        </Flexbox>
                    ) : (
                        <Selector
                            label={options
                                .filter(opt => (selected as (string | number)[]).includes(opt.value))
                                .map(opt => opt.label)
                                .join(', ')}
                            state={selected.length ? 'active' : 'default'}
                            componentType={componentType}
                            dropdownType={dropdownType}
                            size={size}
                            open={isOpen}
                            disabled={disabled}
                            buttonText={buttonText}
                        />
                    );
                }}
                MenuProps={{
                    className: classes('searchableSelect-menu'),
                    PaperProps: {
                        className: classes('searchableSelect-menuPaper'),
                    },
                    ...getMenuPosition(position)
                }}
            >
                <Flexbox vertical className={classes('searchableSelect-menu')}>
                    {searchable && <TextField
                        className={classes('searchableSelect-searchInput')}
                        inputRef={searchInputRef}
                        placeholder="Search..."
                        value={searchText}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onClick={(e) => e.stopPropagation()}
                    />}

                    {title && (
                        <Flexbox vertical className={classes('p-3')}>
                            <Typography variant='body2' className={classes('searchableSelect-title', 'paragraph-15-medium')}>{title}</Typography>
                        </Flexbox>
                    )}

                    <Flexbox vertical className={classes('searchableSelect-menuItems')}>
                        {isLoading ? (
                            <MenuItem disabled>
                                <Flexbox align justify className={classes('w-full')}>
                                    Loading...
                                </Flexbox>
                            </MenuItem>
                        ) : filteredOptions.length === 0 ? (
                            <MenuItem disabled>No options found</MenuItem>
                        ) : (
                            filteredOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    className={classes('searchableSelect-menuItem')}
                                    selected={value.includes(option.value)}
                                    onClick={() => {
                                        if (multiSelect) {
                                            const newValue = value.includes(option.value)
                                                ? value.filter(v => v !== option.value)
                                                : [...value, option.value];
                                            setValue(newValue);
                                            onSelect && onSelect(newValue);
                                        } else {
                                            setValue([option.value]);
                                            setIsOpen(false);
                                            onSelect && onSelect(option.value);
                                        }
                                    }}
                                >
                                    {type === ContentType.TAG ? <Tag label={option.label} type={TagType.IN_PROGRESS} /> :
                                        <Flexbox align justify className={classes('gap-1')}>
                                            {labelStartIcon && <Flexbox>{labelStartIcon}</Flexbox>}
                                            <Typography variant='body2' className={classes('searchableSelect-menuItem-label', 'caption-2-regular')}>{option.label}</Typography>
                                        </Flexbox>
                                    }

                                    {(checkable || multiSelect) && value.includes(option.value) && (
                                        <Flexbox className={classes('searchableSelect-menuItem-checkable')}>
                                            <TickRoundedIcon />
                                        </Flexbox>
                                    )}
                                </MenuItem>
                            ))
                        )}
                    </Flexbox>
                </Flexbox>
            </Select>
        </Flexbox>
    );
};

export default SearchableSelect;