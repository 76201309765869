import { Flexbox, TimeAgo, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, useEffect, useState, useMemo } from 'react'
import ArrowDown from 'components/icons/ArrowDown'
import ArrowUp from 'components/icons/ArrowUp'
import OverviewCommonFields from '../OverviewCommonFields'
import { useDispatch, useSelector } from 'react-redux'
import { initiativeTitleSelector, lastEditedDateSelector, overviewSelector, changeOverviewField } from 'store/initiative-slice'
import { useWorkspaceId } from 'utils/hooks'
import { useLazyGetUsersQuery } from 'store/users-api'

const classes = classNames.bind(styles)

interface OverviewProps {

}

const Overview: FC<OverviewProps> = () => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();

    const [isAllFieldsOpen, setIsAllFieldsOpen] = useState(false);

    const overview = useSelector(overviewSelector);
    const title = useSelector(initiativeTitleSelector);
    const lastEditedDate = useSelector(lastEditedDateSelector);

    const [getUsers] = useLazyGetUsersQuery();

    useEffect(() => {
        const loadData = async () => {
            await Promise.all([
                getUsers({ workspaceId }),
            ])
        }
        loadData()
    }, [])

    const handleToggleAllFields = () => {
        setIsAllFieldsOpen(!isAllFieldsOpen);
    }

    const displayedContent = useMemo(() => {
        const mappedContent = overview?.filter((item: any) => item.fieldKey !== 'title')?.map((item: any) => {
            return {
                key: item.fieldKey,
                value: item.value,
                contentType: item.type,
                label: item.displayName
            }
        })
        if (isAllFieldsOpen || mappedContent.length <= 5) {
            return mappedContent;
        }
        return mappedContent.slice(0, 5);
    }, [overview, isAllFieldsOpen]);

    const handleValueChange = (key: string, value: any) => {
        dispatch(changeOverviewField({ field: key, value }))
    };

    return (
        <Flexbox fullWidth vertical className={classes('overviewContainer', 'gap-3')} id='initiative-overview' data-section-id='overview'>
            <Flexbox vertical className={classes('gap-5')}>
                <Flexbox justifyBetween wrap className={classes('header', 'gap-1')}>
                    <Typography className={classes('title')}>{title}</Typography>
                    {lastEditedDate && <Typography className={classes('description', 'caption-2-regular')}>Last edited <span className={classes('description-value')}><TimeAgo datetime={lastEditedDate} live={false} /></span></Typography>}
                </Flexbox>
                <OverviewCommonFields content={displayedContent} onValueChange={handleValueChange} />
            </Flexbox>

            {overview.length > 5 && (
                <Flexbox vertical align justify className={classes('footer', 'cursor-pointer')} onClick={handleToggleAllFields}>
                    <Typography className={classes('footer-text', 'caption-2-regular')}>{isAllFieldsOpen ? 'See Less' : 'See All Fields'}</Typography>
                    {isAllFieldsOpen ? <ArrowUp /> : <ArrowDown />}
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default Overview