import { Flexbox, IconButton, Typography } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, FC, useState } from 'react';
import ChatAssistant from './ChatAssistant';
import CustomTabs from 'components/CustomTabs';
import { SidebarIcon } from 'components/icons';
import CustomTab from 'components/CustomTabs/CustomTab';
import Comments from './Comments';
import FeatureFlagEnabled from 'components/FeatureFlagEnabled';
const classes = classNames.bind(styles);

interface AssistantPanelProps {
}

const AssistantPanel: FC<AssistantPanelProps> = () => {
    const [activeTab, setActiveTab] = useState<'aiAssistant' | 'comments'>('aiAssistant');

    return (
        <Flexbox
            className={classes('assistant-panel')}
            vertical
            justifyBetween
        >
            <Flexbox vertical className={classes('header-container')}>
                <Flexbox justifyBetween align className={classes('header')}>
                    {/* <Typography className={classes('header-title', 'body-bold')}>
                        Assistant
                    </Typography> */}
                    {/* <IconButton className={classes('header-icon')}>
                        <SidebarIcon />
                    </IconButton> */}
                </Flexbox>
                <CustomTabs
                    sx={{ width: '100%' }}
                    className={classes('w-full')}
                    value={activeTab}
                    onChange={(_event: ChangeEvent<{}>, value: 'aiAssistant' | 'comments') => setActiveTab(value)}
                >
                    <CustomTab key='aiAssistant' value='aiAssistant' label='Assistant' />
                    <FeatureFlagEnabled flagKey='initiativesV2Comments'>
                        <CustomTab key='comments' value='comments' label='Comments' />
                    </FeatureFlagEnabled>
                </CustomTabs>
            </Flexbox>
            {activeTab === 'aiAssistant' && <ChatAssistant />}
            {activeTab === 'comments' && <Comments />}
        </Flexbox>
    )
}

export default AssistantPanel;