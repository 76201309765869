import { Flexbox, Typography, Tooltip } from 'components';
import Markdown from 'components/Markdown';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { editModeSelector } from 'store/initiative-slice';

const classes = classNames.bind(styles);

interface RequirementItemProps {
    title: string;
    description?: string;
    requirementId: string;
    isContentMinimized: boolean;
    isActive?: boolean;
    scrollToSection: () => void;
}

const RequirementItem: FC<RequirementItemProps> = ({
    title,
    description,
    requirementId,
    isContentMinimized,
    isActive,
    scrollToSection
}) => {
    const editMode = useSelector(editModeSelector);
    // Extract first 4 lines of description (if available)
    const tooltipContent = useMemo(() => {
        if (!description) {
            return title;
        }
        // Get first 4 lines or less
        const lines = description.split('\n').filter(line => line.trim().length > 0).slice(0, 4);
        const truncatedDescription = lines.join('\n');
        return (
            <div className={classes('tooltip-content')}>
                <Typography className={classes('tooltip-title')}>{title}</Typography>
                {truncatedDescription && (
                    <Markdown text={truncatedDescription} className={classes('tooltip-description')} />
                )}
            </div>
        );
    }, [title, description]);

    return (
        <Flexbox
            fullWidth
            align
            justify={isContentMinimized}
            className={classes('navigation-item', 'requirement-item', {
                active: isActive,
                draggable: editMode
            })}
            onClick={scrollToSection}
            style={{ cursor: editMode ? 'grab' : 'pointer' }}
        >
            {!isContentMinimized && (
                <Tooltip
                    title={tooltipContent}
                    placement='right'
                    enterDelay={1000}
                    classes={{ tooltip: classes('requirement-tooltip') }}
                >
                    <Typography
                        className={classes('navigation-item-title', 'caption-2-regular', 'truncate')}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%'
                        }}
                    >
                        {title}
                    </Typography>
                </Tooltip>
            )}
        </Flexbox>
    )
}

export default RequirementItem;