import { Flexbox, Snackbar } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { BoltIcon, CloseIcon } from 'components/icons';
import MessageBox from './MessageBox';
import SidePanelInput from './SidePanelInput';
import { useEffect, useState, KeyboardEvent, useRef } from 'react';
import {
    messagesSelector,
    assistantStreamingTextSelector,
    setMessages,
    setIsLoadingAssistant,
    setGenerateInitiativePrompt,
    generateInitiativePromptSelector,
    selectedTemplateSelector,
    setAssistantStreamingText,
    isLoadingAssistantSelector,
    sessionIdSelector,
    sectionToRefineSelector,
    setSectionToRefine,
    changeOverviewField,
    changeMainSimpleTextContentField,
    currentInitiativeSelector,
    setEditMode,
    editModeSelector,
    setRequirementItems,
    setDragKey,
    dragKeySelector,
    setMockups,
    blocksSelector,
    mainSections,
    updateCustomSection,
} from 'store/initiative-slice';
import { useSelector, useDispatch } from 'react-redux';
import { AIAnswerTypes, AMAReference, AMAResponseTypes, AMAUserFeedback, AssistantMessage, GenerationPlan, Message, PRDMainType, TagType } from 'utils/types';
import { v4 as uuid } from 'uuid';
import { useCreateInitiativeV2MockupMutation, useSendMessageMutation } from 'store/initiative-api';
import { capitalize } from 'utils/string';
import Tag from 'components/Tag';
import { getRelevantReferences } from 'store/assistant-slice';
import FeedbackDialog from 'pages/Assistant/components/FeedbackDialog';
import { sendFeedback } from 'pages/Assistant/assistant.api';
import { userSelector } from 'store/user';
import store from 'store';
import { useWorkspaceId, useAddCustomSection } from 'utils/hooks';
import Tooltip from 'components/Tooltip';

const classes = classNames.bind(styles);

export const overviewFields = [
    'type',
    'priority',
    'size',
    'status',
    'startDate',
    'endDate',
    'liveDate',
    'title',
]

export const inputTypeFields = [
    'problem',
    'solution',
    'validation',
];

// Helper function to get field display name from template
const getFieldDisplayNameFromTemplate = (fieldKey: string): string | null => {
    const templateSections = store.getState().initiative.selectedTemplate?.content?.sections || [];
    // Look through all sections in the template
    for (const section of templateSections) {
        // Look for the field in the current section
        const field = section.fields.find(f => f.fieldKey === fieldKey);
        if (field?.displayName) {
            return field.displayName;
        }
    }
    return null;
}

const getSectionDisplayName = (sectionKey: string, sectionContent?: any): string => {
    // Get the template from the store
    const templateSections = store.getState().initiative.selectedTemplate?.content?.sections || [];
    const templateSection = templateSections.find(section => section.key === sectionKey);

    const isCustomSection = !mainSections.includes(sectionKey);
    if (isCustomSection) {
        return sectionContent?.title || 'Untitled Section';
    }

    // If we have a template section with a name, use that instead of hardcoded values
    if (templateSection?.name) {
        return templateSection.name;
    }
    // Check if this is a field that might have a display name in the template
    const fieldDisplayName = getFieldDisplayNameFromTemplate(sectionKey);
    if (fieldDisplayName) {
        return fieldDisplayName;
    }
    // Fallback to existing logic for cases where template data is not available
    switch (sectionKey) {
        case 'problem': return 'Problem Statement';
        case 'validation': return 'Validation Statement';
        case 'solution': return 'Solution';
        case 'requirements': return 'Requirements';
        case 'mockups': return 'Mockups';
        case 'okrs': return 'OKRs & Impact';
        case 'resources': return 'Resources';
        case 'requirement':
            // For requirements, the content could be passed in different formats
            // Option 1: As a simple string (the title)
            if (typeof sectionContent === 'string' && sectionContent.trim()) {
                // Limit title length to 15 characters
                const maxLength = 15;
                return sectionContent.length > maxLength ?
                    sectionContent.substring(0, maxLength) + '...' :
                    sectionContent;
            }
            // Option 2: As an object with title property
            else if (sectionContent && typeof sectionContent === 'object') {
                if (sectionContent.content && sectionContent.content.title) {
                    const title = sectionContent.content.title;
                    const maxLength = 15;
                    return title.length > maxLength ?
                        title.substring(0, maxLength) + '...' :
                        title;
                }
                else if (sectionContent.title) {
                    const title = sectionContent.title;
                    const maxLength = 15;
                    return title.length > maxLength ?
                        title.substring(0, maxLength) + '...' :
                        title;
                }
            }
            return 'Requirement';
        default: return capitalize(sectionKey);
    }
}

const getFullRequirementName = (sectionKey: string, sectionContent?: any): string | null => {
    // Only return a tooltip for requirements
    if (sectionKey !== 'requirement') {
        return null;
    }
    // Extract the full title based on content format
    if (typeof sectionContent === 'string' && sectionContent.trim()) {
        return sectionContent;
    } else if (sectionContent && typeof sectionContent === 'object') {
        if (sectionContent.content && sectionContent.content.title) {
            return sectionContent.content.title;
        } else if (sectionContent.title) {
            return sectionContent.title;
        }
    }
    return null;
}

const getPlaceholderForSection = (sectionKey?: string): string => {
    if (!sectionKey) {
        return 'Ask a question...';
    }
    // Get field name from template if available
    const templateFieldName = getFieldDisplayNameFromTemplate(sectionKey) ||
        getSectionDisplayName(sectionKey);
    const fieldNameText = templateFieldName || capitalize(sectionKey);
    const placeholders: Record<string, string[]> = {
        'problem': [
            `e.g. Ask about ${fieldNameText} insights...`,
            `e.g. Explore this ${fieldNameText} further...`,
            `e.g. Help me understand this ${fieldNameText} better...`
        ],
        'validation': [
            `e.g. Ask about supporting data for ${fieldNameText}...`,
            `e.g. Questions about user research for ${fieldNameText}...`,
            `e.g. Help me with ${fieldNameText} metrics...`
        ],
        'solution': [
            `e.g. Ask about implementation details for ${fieldNameText}...`,
            `e.g. Explore alternative approaches for ${fieldNameText}...`,
            `e.g. Questions about specific features of ${fieldNameText}...`
        ],
        'requirements': [
            `e.g. Help me understand these ${fieldNameText}...`,
            `e.g. Ideas for these ${fieldNameText}...`,
            `e.g. Questions about ${fieldNameText} scope...`
        ],
        'requirement': [
            `e.g. Ask about this ${fieldNameText}...`,
            `e.g. Help me understand this ${fieldNameText}...`,
            `e.g. Suggestions for this ${fieldNameText}...`
        ],
        'mockups': [
            `e.g. Questions about this ${fieldNameText}...`,
            `e.g. Help me understand UI decisions in ${fieldNameText}...`,
            `e.g. Explore user experience elements in ${fieldNameText}...`
        ],
        'okrs': [
            `e.g. Help me define outcomes for ${fieldNameText}...`,
            `e.g. Questions about KPIs for ${fieldNameText}...`,
            `e.g. Ideas for success metrics in ${fieldNameText}...`
        ],
        'resources': [
            `e.g. Ask about resource allocation for ${fieldNameText}...`,
            `e.g. Help with ${fieldNameText} planning...`,
            `e.g. Questions about ${fieldNameText} constraints...`
        ]
    };
    // Default generic placeholders if the section key is not in our predefined list
    const defaultPlaceholders = [
        `e.g. Ask about this ${fieldNameText}...`,
        `e.g. Help me understand this ${fieldNameText}...`,
        `e.g. Questions about this ${fieldNameText}...`
    ];
    const options = placeholders[sectionKey] || defaultPlaceholders;
    // Randomly select one of the options
    const randomIndex = Math.floor(Math.random() * options.length);
    return options[randomIndex];
}

const parseSectionContentTitleAndDescription = (content: string) => {
    // Try to extract title and description
    const titleMatch = content.match(/<title>(.*?)<\/title>/);
    const title = titleMatch ? titleMatch[1].trim() : '';

    // Get description - everything after the title or the whole content if no title
    let description = '';
    if (titleMatch) {
        description = content.substring(titleMatch[0].length).trim();
    } else {
        description = content;
    }

    // Clean up the description by removing any XML-like tags
    description = description
        .replace(/<\/title>\s*/g, '')
        .replace(/<description>\s*/g, '')
        .replace(/<\/description>\s*/g, '')
        .replace(/<title>\s*/g, '')
        .replace(/\/title>\s*/g, '')  // Handle incomplete closing tags
        .replace(/description>\s*/g, '')  // Handle incomplete closing tags
        .replace(/title>\s*/g, '')  // Handle incomplete opening tags
        .replace(/<\/\s*/g, '')  // Handle any other incomplete closing tags
        .replace(/\s*<\s*/g, '')  // Clean up any leftover angle brackets with spaces
        .trim();

    return {
        title,
        description
    };
};

export const formatData = (data: any) => {
    const formattedData: Record<string, any> = {};

    // Process each field in the data
    Object.entries(data).forEach(([key, value]) => {
        // Skip empty fields
        if (!value) { return };

        // Handle requirements field specially
        if (key === 'requirements' && Array.isArray(value)) {
            const parsedRequirements = value.map((req: any) => {
                const reqContent = req.content || '';

                const { title, description } = parseSectionContentTitleAndDescription(reqContent);

                return {
                    content: {
                        title,
                        description,
                    },
                    field_generation_id: req.field_generation_id,
                    field_id: req.field_id
                };
            });

            formattedData[key] = parsedRequirements;
            return;
        }
        // Handle overview fields (type, priority, size, status, startDate, endDate, liveDate)
        if (overviewFields.includes(key)) {
            const stringValue = String(value);
            // Extract content from XML-like tags
            const match = stringValue.match(/<([^>]+)>([^<]+)<\/\1>/);

            if (match) {
                const content = match[2];

                // Format according to the field type
                if (key.includes('Date')) {
                    formattedData[key] = {
                        content: { date: content },
                        field_type: key
                    };
                } else {
                    formattedData[key] = {
                        content: { name: content },
                        field_type: key
                    };
                }
            }
        }
        // Handle other fields like problem, validation, solution, requirements
        else {
            const stringValue = String(value);

            // For requirements field which has a more complex structure
            if (key === 'requirements') {
                // Extract title and description from the requirements content
                const titleMatch = stringValue.match(/<title>([^<]+)<\/title>/);
                const title = titleMatch ? titleMatch[1] : '';

                formattedData[key] = [{
                    content: {
                        title: title,
                        description: stringValue
                    },
                    field_type: key
                }];
            }
            // For other fields like problem, validation, solution
            else {
                // Extract content from XML-like tags or use the whole string
                const match = stringValue.match(/<([^>]+)>([\s\S]*?)<\/\1>/);
                const content = match ? match[2] : stringValue;

                formattedData[key] = {
                    content: { [key]: content },
                    field_type: key
                };
            }
        }
    });

    return formattedData;
}

const ChatAssistant = () => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();
    const chatBodyRef = useRef<HTMLDivElement>(null);

    const [sendMessage] = useSendMessageMutation();
    const [createMockup] = useCreateInitiativeV2MockupMutation();
    const { handleAddCustomSection } = useAddCustomSection();

    const [question, setQuestion] = useState('');

    const messages = useSelector(messagesSelector);
    const assistantStreamingText = useSelector(assistantStreamingTextSelector);
    const generateInitiativePrompt = useSelector(generateInitiativePromptSelector);
    const selectedTemplate = useSelector(selectedTemplateSelector);
    const isLoadingAssistant = useSelector(isLoadingAssistantSelector);
    const sessionId = useSelector(sessionIdSelector);
    const sectionToRefine = useSelector(sectionToRefineSelector);
    const user = useSelector(userSelector);
    const currentInitiative = useSelector(currentInitiativeSelector);
    const editMode = useSelector(editModeSelector);
    const dragKey = useSelector(dragKeySelector);
    const blocks = useSelector(blocksSelector);

    const [feedbackStatus, setFeedbackStatus] = useState<'initial' | 'pending' | 'success' | null>(null)
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Sorry, An error occurred. Please try again later!');
    const [feedback, setFeedback] = useState<AMAUserFeedback | null>(null)
    const [latestMessageId, setLatestMessageId] = useState<number | null>(null)

    const handleQuestionChange = (value: string) => {
        setQuestion(value);
    }

    useEffect(() => {
        if (generateInitiativePrompt) {
            onSendMessage(true);
        }
    }, [generateInitiativePrompt]);

    const handleSendFeedback = (feedbackType: 'positive' | 'negative') => {
        setFeedback({ type: feedbackType, content: '' })
        setFeedbackStatus('pending')
    }

    const onResetFeedback = () => {
        setFeedbackStatus(null);
        setFeedback(null)
    }

    const dispatchActions = (field_type: string, content: any) => {
        if (overviewFields.includes(field_type)) {
            dispatch(changeOverviewField({ field: field_type, value: Object.values(content)[0] as string }));
        } else if (inputTypeFields.includes(field_type)) {
            dispatch(changeMainSimpleTextContentField({ field: field_type, value: Object.values(content)[0] as string }));
        }
    }

    function prepareInitiativeData(data: any) {
        const result: any = {};

        Object.keys(data).forEach(sectionKey => {
            const section = data[sectionKey];

            if (sectionKey === 'mockups') {
                result[sectionKey] = section.map((item: any) => {
                    const value = item.value.map((mockup: any) => {
                        const matchingBlock = blocks.find((block: any) => block.id === mockup.id);

                        if (matchingBlock) {
                            return {
                                ...mockup,
                                content: {
                                    ...mockup.content,
                                    signedUrl: matchingBlock.metadata.signedUrl
                                }
                            };
                        }
                        return mockup;
                    });

                    return {
                        ...item,
                        value
                    };
                });
            } else if (Array.isArray(section)) {
                result[sectionKey] = section.map(item => ({
                    fieldKey: item.fieldKey,
                    displayName: item.displayName,
                    type: item.type,
                    options: item.options || [],
                    value: item.value
                }));
            }
        });

        return result;
    }

    const setFinalMessageContent = (answerType: AIAnswerTypes, prdResponse: any, mockupValue: any, generatedText: string, customSectionValue: any) => {
        if (answerType === AIAnswerTypes.PRD_FIRST_GENERATION) {
            return prdResponse
        }
        // Show inside chat prd preview data
        else if (answerType === AIAnswerTypes.PRD_PREVIEW || answerType === AIAnswerTypes.PRD_PREVIEW_REFINEMENT) {
            return prdResponse
        }
        // Show mockup data (Upcoming)
        else if (answerType === AIAnswerTypes.MOCKUP_STREAM || answerType === AIAnswerTypes.MOCKUP_PREVIEW) {
            return mockupValue
        }
        // Show custom section data
        else if (answerType === AIAnswerTypes.CUSTOM_SECTION_STREAM || answerType === AIAnswerTypes.CUSTOM_SECTION_PREVIEW) {
            return customSectionValue
        }
        // Show generated text
        else if (answerType === AIAnswerTypes.TEXT) {
            return generatedText.trim().replace('```', '')
        }
    }

    const onSendMessage = (isFirstGeneration: boolean = false) => {
        // Check if there's an actual query to send
        if (!generateInitiativePrompt && question.trim() === '') {
            return;
        }

        setFeedbackStatus('initial');

        let prdStreamingValue: any = {
            title: '',
            problem: '',
            validation: '',
            solution: '',
            requirements: [],
        }

        let mockupStreamingValue: any = {
            title: '',
            html: '',
        }

        let customSectionStreamingValue: string = ''

        let promptPrefix: string = generateInitiativePrompt ? 'Generate a PRD:' : ''

        let context: any[] = [
            {
                type: 'template',
                content: selectedTemplate,
            },
            {
                type: 'global',
                content: prepareInitiativeData(currentInitiative),
            }
        ]

        if (sectionToRefine && currentInitiative) {
            promptPrefix = `${sectionToRefine.type === 'generate' ? 'Generate' : 'Refine'} the ${sectionToRefine?.key || ''} section:`

            context.push({
                type: 'local',
                content: prepareInitiativeData(currentInitiative)[sectionToRefine?.key],
            })
        }
        dispatch(setSectionToRefine(null));
        // Final message Values
        let answerType: AIAnswerTypes = AIAnswerTypes.TEXT
        let generatedText = '';
        let jsonResponse: any = {}
        let references: AMAReference[] = []
        const newDiscussionId = uuid();
        let stream_logs: string[] = [];
        let stream_plan: GenerationPlan[] = [];
        let field_generation_id: string | null = null;

        let mockupValue: any = {
            content: {},
            field_generation_id: null
        }
        let customSectionValue: any = {
            content: {},
            field_generation_id: null
        }

        const newMessages: Message[] = [
            {
                from: 'user',
                text: generateInitiativePrompt ? generateInitiativePrompt : question,
                references: [],
                id: newDiscussionId
            },
            ...messages
        ];

        dispatch(setMessages(newMessages));
        dispatch(setGenerateInitiativePrompt(''));
        dispatch(setIsLoadingAssistant(true));
        setQuestion('');

        let assistantMessage: AssistantMessage = {
            from: 'assistant',
            text: {
                type: AIAnswerTypes.TEXT,
                content: ''
            },
            references: [],
            stream_logs: [],
            stream_plan: [],
            id: newDiscussionId
        }

        sendMessage({
            query: generateInitiativePrompt ? generateInitiativePrompt : question,
            sessionId,
            promptPrefix,
            onResponse: (value: any, type?: AMAResponseTypes) => {
                if (store.getState().initiative.sessionId !== value.session_id) {
                    dispatch(setIsLoadingAssistant(false));
                    return;
                }

                if (type === AMAResponseTypes.LOG) {
                    assistantMessage = {
                        ...assistantMessage,
                        stream_logs: [...(assistantMessage.stream_logs || []), value.message as string]
                    }

                    stream_logs = [...(stream_logs || []), value.message as string]
                    const updatedMessages = [assistantMessage, ...newMessages];
                    dispatch(setMessages(updatedMessages));
                } else if (type === AMAResponseTypes.METADATA) {
                    setLatestMessageId(value.message_id)
                    references = Array.isArray(value.references) ? getRelevantReferences(value.references) : []
                } else if (type === AMAResponseTypes.FINISH) {
                    dispatch(setIsLoadingAssistant(false));
                    dispatch(setMessages([
                        {
                            id: newDiscussionId,
                            from: 'assistant',
                            text: {
                                type: answerType,
                                content: setFinalMessageContent(answerType, jsonResponse, mockupValue, generatedText, customSectionValue.content),
                                field_generation_id
                            },
                            references,
                            stream_logs,
                            stream_plan
                        },
                        ...newMessages
                    ]));

                    dispatch(setSectionToRefine(null));
                    generatedText = '';
                    mockupStreamingValue = {};
                    mockupValue = {};
                    customSectionStreamingValue = '';
                    field_generation_id = null;
                    return;
                } else {
                    let text = '';

                    if (value.finish && stream_plan && value.stream_id) {
                        stream_plan = stream_plan.map(item => {
                            if (item.stream_id === value.stream_id) {
                                return { ...item, finish: true };
                            }
                            return item;
                        });
                    }

                    if (value.type === AIAnswerTypes.PLAN) {
                        stream_plan = value.content

                        dispatch(setMessages([
                            {
                                ...assistantMessage,
                                stream_plan
                            },
                            ...newMessages
                        ]));
                    } else if (value.type === AIAnswerTypes.TEXT) {
                        text = value.content;

                        generatedText = (generatedText + text).replace('```html', '')

                        dispatch(setMessages([
                            {
                                ...assistantMessage,
                                text: {
                                    type: AIAnswerTypes.TEXT,
                                    content: generatedText
                                },
                                stream_logs: stream_logs,
                                stream_plan: stream_plan,
                                references: references
                            },
                            ...newMessages
                        ]));

                        dispatch(setAssistantStreamingText(generatedText));
                    } else if (value.type === AIAnswerTypes.CUSTOM_SECTION_STREAM) {
                        customSectionStreamingValue += value.content || '';

                        const { title, description } = parseSectionContentTitleAndDescription(customSectionStreamingValue);

                        dispatch(setMessages([
                            {
                                ...assistantMessage,
                                text: {
                                    type: AIAnswerTypes.CUSTOM_SECTION_STREAM,
                                    content: {
                                        title,
                                        description
                                    }
                                },
                                stream_logs: stream_logs,
                                stream_plan: stream_plan,
                                references: references
                            },
                            ...newMessages
                        ]));
                    } else if (value.type === AIAnswerTypes.CUSTOM_SECTION_PREVIEW) {
                        customSectionValue = value.content
                        answerType = AIAnswerTypes.CUSTOM_SECTION_PREVIEW
                        field_generation_id = value.content.field_generation_id
                        customSectionStreamingValue = '';
                    } else if (value.type === AIAnswerTypes.PRD_PREVIEW) {
                        jsonResponse = value.content
                        answerType = isFirstGeneration ? AIAnswerTypes.PRD_FIRST_GENERATION : AIAnswerTypes.PRD_PREVIEW;
                        // Reset prdStreamingValue when PRD is Ready
                        prdStreamingValue = {}
                    } else if (value.type === AIAnswerTypes.PRD_PREVIEW_STREAM) {
                        const { content, field_type, field_generation_id, field_id } = value.content;

                        // TODO: This should be global handling for all array fields
                        if (field_type === 'requirements') {
                            // Initialize requirements as an array if it doesn't exist
                            if (!prdStreamingValue[field_type]) {
                                prdStreamingValue = {
                                    ...prdStreamingValue,
                                    [field_type]: []
                                };
                            }

                            // Check if we already have a requirement with this field_generation_id
                            const existingReqIndex = prdStreamingValue[field_type].findIndex(
                                (req: any) => req.field_generation_id === field_generation_id
                            );

                            if (existingReqIndex >= 0) {
                                // Update existing requirement
                                const updatedRequirements = [...prdStreamingValue[field_type]];
                                updatedRequirements[existingReqIndex] = {
                                    ...updatedRequirements[existingReqIndex],
                                    content: updatedRequirements[existingReqIndex].content + content,
                                    field_generation_id,
                                    field_id
                                };

                                prdStreamingValue = {
                                    ...prdStreamingValue,
                                    [field_type]: updatedRequirements
                                };
                            } else {
                                // Add new requirement
                                prdStreamingValue = {
                                    ...prdStreamingValue,
                                    [field_type]: [
                                        ...prdStreamingValue[field_type],
                                        {
                                            content,
                                            field_generation_id,
                                            field_id
                                        }
                                    ]
                                };
                            }
                        } else {
                            prdStreamingValue = {
                                ...prdStreamingValue,
                                [field_type]: prdStreamingValue[field_type] ? prdStreamingValue[field_type] + content : content
                            };
                        }

                        jsonResponse = formatData(prdStreamingValue)

                        dispatch(setMessages([
                            {
                                id: newDiscussionId,
                                from: 'assistant',
                                text: {
                                    type: AIAnswerTypes.PRD_PREVIEW_STREAM,
                                    content: formatData(prdStreamingValue)
                                },
                                references,
                                stream_logs,
                                stream_plan
                            },
                            ...newMessages
                        ]));

                        if (isFirstGeneration) {
                            if (overviewFields.includes(field_type)) {
                                setTimeout(() => {
                                    dispatch(changeOverviewField({ field: field_type, value: Object.values(jsonResponse[field_type].content)[0] as string }));
                                }, 1000);
                            } else if (inputTypeFields.includes(field_type)) {
                                setTimeout(() => {
                                    dispatch(changeMainSimpleTextContentField({ field: field_type, value: Object.values(jsonResponse[field_type].content)[0] as string }));
                                }, 1000);
                            } else if (field_type === 'requirements') {
                                setTimeout(() => {
                                    dispatch(setRequirementItems(jsonResponse[field_type]));
                                }, 1000);
                            }
                        }
                    } else if (value.type === AIAnswerTypes.PRD_PREVIEW_REFINEMENT) {
                        jsonResponse = value.content
                        answerType = AIAnswerTypes.PRD_PREVIEW_REFINEMENT
                    } else if (value.type === AIAnswerTypes.MOCKUP_STREAM) {
                        const { content, field_type, field_generation_id } = value.content;

                        mockupStreamingValue = {
                            ...mockupStreamingValue,
                            [field_type]: mockupStreamingValue[field_type] ? mockupStreamingValue[field_type] + content : content
                        };

                        dispatch(setMessages([
                            {
                                id: newDiscussionId,
                                from: 'assistant',
                                text: {
                                    type: AIAnswerTypes.MOCKUP_STREAM,
                                    content: { content: mockupStreamingValue, field_generation_id },
                                    field_generation_id
                                },
                                references,
                                stream_logs,
                                stream_plan,
                                streaming: !value.finish,
                                stream_id: value.stream_id
                            },
                            ...newMessages
                        ]));

                    } else if (value.type === AIAnswerTypes.MOCKUP_PREVIEW) {
                        mockupStreamingValue = value.content
                        answerType = AIAnswerTypes.MOCKUP_PREVIEW
                        mockupValue = {
                            content: value.content,
                            field_generation_id: value.field_generation_id
                        }
                        mockupStreamingValue = {}
                    }
                }
            },
            onError: (error: any) => {
                setError(true);
                dispatch(setIsLoadingAssistant(false));
                if (error.message) {
                    setErrorMessage(error.message);
                }
            },
            context
        });
    }

    const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSendMessage();
        }
    };

    const handleSubmitFeedback = async () => {
        if (latestMessageId && sessionId && feedback) {
            try {
                await dispatch(sendFeedback({ userId: user.id, sessionId, messageId: latestMessageId, body: feedback }))
                setFeedbackStatus('success');
            } catch (err) {
                console.log(err);
            }

            setTimeout(() => {
                onResetFeedback()
            }, 1500);
        }
    }

    const scrollToBottom = () => {
        // Store reference to avoid null check issues
        const chatBody = chatBodyRef.current;
        if (chatBody) {
            // Use setTimeout to delay the scroll slightly, allowing transitions to complete
            setTimeout(() => {
                // Use requestAnimationFrame to ensure DOM is updated before scrolling
                requestAnimationFrame(() => {
                    if (chatBodyRef.current) {
                        chatBodyRef.current.scrollTo({
                            top: chatBodyRef.current.scrollHeight,
                            behavior: 'smooth'
                        });
                    }
                });
            }, 80); // Short delay helps with smooth transitions
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, assistantStreamingText]);

    const handleInsertPRD = async (message: AssistantMessage) => {
        if (!editMode) {
            dispatch(setEditMode(true));
        }

        const updateRequirements = (requirements: any[], newRequirement: any) => {
            const existingRequirements = requirements || [];

            if (Array.isArray(newRequirement)) {
                return existingRequirements.map((existingReq: any) => {
                    const updatedRequirement = (message.text.content as PRDMainType).requirements.find(
                        (newReq: any) => newReq.field_generation_id === existingReq.field_generation_id
                    );
                    return updatedRequirement
                        ? { ...existingReq, content: updatedRequirement.content }
                        : existingReq;
                }).concat(
                    (message.text.content as PRDMainType).requirements.filter((newReq: any) =>
                        !existingRequirements.some(
                            (existingReq: any) => existingReq.field_generation_id === newReq.field_generation_id
                        )
                    )
                );
            }

            const hasExisting = existingRequirements.some(
                (req: any) => req.field_generation_id === newRequirement.field_generation_id
            );

            return hasExisting
                ? existingRequirements.map((req: any) =>
                    req.field_generation_id === newRequirement.field_generation_id
                        ? { ...req, content: newRequirement.content }
                        : req
                )
                : [...existingRequirements, {
                    content: newRequirement.content,
                    field_generation_id: newRequirement.field_generation_id,
                    field_id: newRequirement.field_id
                }];
        };

        if (message.text.type === AIAnswerTypes.MOCKUP_PREVIEW) {
            const currentMockups = currentInitiative?.mockups[0]?.value || [];
            const existingMockup = currentMockups.find((mockup: any) => mockup.field_generation_id === (message.text.content as { field_generation_id: string }).field_generation_id);
            try {
                const mockupResp = await createMockup({
                    workspaceId: workspaceId,
                    mockup: { ...message.text.content.content }
                });

                if (!existingMockup || !existingMockup?.id) {
                    if ('data' in mockupResp) {
                        dispatch(setMockups([...currentMockups, { field_generation_id: (message.text.content as { field_generation_id: string }).field_generation_id, id: mockupResp.data.id, content: { title: (message.text.content as any).content.title } }]));
                    }
                } else if ('data' in mockupResp && mockupResp.data?.id) {
                    dispatch(setMockups(currentMockups.map((mockup: any) =>
                        mockup.field_generation_id === (message.text.content as { field_generation_id: string }).field_generation_id
                            ? { ...mockup, id: mockupResp.data.id, content: { title: (message.text.content as any).content.title } }
                            : mockup
                    )));
                }
            } catch (error) {
                console.error('Failed to create/update mockup:', error);
            }
        } else if (message.text.type === AIAnswerTypes.CUSTOM_SECTION || message.text.type === AIAnswerTypes.CUSTOM_SECTION_PREVIEW) {
            if (!currentInitiative) {
                return;
            }

            const customSections = Object.entries(currentInitiative)
                .filter(([key]) => !mainSections.includes(key))
                .map(([key, value]) => ({ key, value: value[0].value }));

            const existingCustomSection = customSections.find((section: any) => section.value.field_generation_id === (message.text as any).field_generation_id);

            if (existingCustomSection) {
                dispatch(updateCustomSection({
                    sectionKey: existingCustomSection.key,
                    sectionData: {
                        ...message.text.content,
                        field_generation_id: existingCustomSection.value.field_generation_id
                    }
                }));
            } else {
                handleAddCustomSection({
                    title: (message.text.content as any).title,
                    description: (message.text.content as any).description,
                    field_generation_id: (message.text as any).field_generation_id
                });
            }
        }

        Object.values(message.text.content).forEach((value: any) => {
            if (Array.isArray(value) || (value.field_type === 'requirements')) {
                const currentRequirements = currentInitiative?.requirements[0]?.value || [];
                dispatch(setRequirementItems(updateRequirements(currentRequirements, value)));
            } else {
                dispatchActions(value.field_type, value.content);
            }
            dispatch(setDragKey(dragKey + 1));
        });
    };

    return (
        <Flexbox vertical className={classes('chat-assistant')}>
            <Flexbox vertical className={classes('chat-assistant-body')} ref={chatBodyRef}>
                {[...messages].reverse().map((message: any, index, reversedArray) => {
                    // Find original index in the messages array (before reversal)
                    const originalIndex = messages.findIndex(msg => msg.id === message.id);
                    return (
                        <MessageBox
                            key={`${message.id}-${index}`}
                            message={message}
                            handleSendFeedback={handleSendFeedback}
                            closeFeedbackConversation={() => setFeedbackStatus(null)}
                            feedbackStatus={feedbackStatus}
                            showFeedbackConversation={message.from === 'assistant' && message.text.type !== AIAnswerTypes.PRD_PREVIEW_STREAM && message.text.type !== AIAnswerTypes.MOCKUP_PREVIEW && index === 0 && !!feedbackStatus}
                            handleInsertPRD={handleInsertPRD}
                            isLoading={isLoadingAssistant && originalIndex === 0}
                            isLatestMessage={originalIndex === 0}
                        />
                    );
                })}
            </Flexbox>
            <Flexbox vertical className={classes('chat-assistant-footer')}>
                {sectionToRefine && (
                    <Flexbox align className={classes('header')}>
                        <Flexbox className={classes('header-title')}>AI Focus:</Flexbox>
                        {sectionToRefine.key === 'requirement' && getFullRequirementName(sectionToRefine.key, sectionToRefine.content) ? (
                            <Tooltip title={getFullRequirementName(sectionToRefine.key, sectionToRefine.content) || ''} arrow>
                                <div>
                                    <Tag
                                        label={getSectionDisplayName(sectionToRefine?.key || '', sectionToRefine?.content)}
                                        type={TagType.IN_PROGRESS}
                                        onEndIconClick={() => dispatch(setSectionToRefine(null))}
                                        endIcon={<CloseIcon />}
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            <Tag
                                label={getSectionDisplayName(sectionToRefine?.key || '', sectionToRefine?.content)}
                                type={TagType.IN_PROGRESS}
                                onEndIconClick={() => dispatch(setSectionToRefine(null))}
                                endIcon={<CloseIcon />}
                            />
                        )}
                    </Flexbox>
                )}

                <SidePanelInput
                    placeholder={sectionToRefine ? getPlaceholderForSection(sectionToRefine.key) : 'Ask a question...'}
                    value={question}
                    onChange={handleQuestionChange}
                    buttonIcon={<BoltIcon />}
                    disabled={isLoadingAssistant}
                    onClick={onSendMessage}
                    onKeyDown={onKeyPress}
                    sendButtonDisabled={question.trim().length === 0}
                />
            </Flexbox>

            {
                feedback && <FeedbackDialog
                    pendingStatus={feedbackStatus === 'pending'}
                    handleSubmitFeedback={handleSubmitFeedback}
                    feedback={feedback}
                    handleChangeFeedback={e => setFeedback({ type: feedback.type, content: e.target.value })}
                />
            }

            <Snackbar open={error} onClose={() => setError(false)} type="error">
                <Flexbox>{errorMessage}</Flexbox>
            </Snackbar>
        </Flexbox>
    )
}

export default ChatAssistant;