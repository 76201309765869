import { createApi } from '@reduxjs/toolkit/query/react';
import { ASSISTANT_URL, BASE_URL_BASE } from 'utils/constants';
import { emit } from 'utils/request';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const initiativeApi = createApi({
    reducerPath: 'initiative.api',
    baseQuery: (args, api, extraOptions) => rtkBaseQueryWithReauth(args, api, extraOptions, { customUrl: true }),
    tagTypes: ['initiative'],
    endpoints: builder => ({
        // ___________________________________New APIs
        getInitiativeTemplates: builder.query({
            query: ({ workspaceId }) => `${BASE_URL_BASE}/v1/workspaces/${workspaceId}/initiative-templates`,
        }),
        getUserSession: builder.query({
            query: ({ workspaceId, userId, sessionId }) => ({
                url: `${ASSISTANT_URL}api/v1/workspaces/${workspaceId}/users/${userId}/assistant/sessions/${sessionId}`,
                method: 'GET',
            }),
        }),
        sendMessage: builder.mutation({
            queryFn: async ({ query, sessionId, onResponse, onError, context, promptPrefix }, { getState }) => {
                const state = getState() as { user: { token: string, workspace: { id: number } } };

                return new Promise((resolve, reject) => {
                    emit('message',
                        {
                            context,
                            prompt: query,
                            promptPrefix: promptPrefix || '',
                            sessionId,
                            workspaceId: state.user.workspace.id,
                            Authorization: `Bearer ${state.user.token}`
                        },
                        (response, type) => {
                            onResponse(response, type);
                            resolve({ data: response });
                        },
                        (error) => {
                            onError(error);
                            reject({ error });
                        }
                    );
                });
            }
        }),
        createInitiativeV2: builder.mutation({
            query: ({ workspaceId, initiative }) => {
                return {
                    url: `${BASE_URL_BASE}/v2/workspaces/${workspaceId}/initiatives`,
                    method: 'POST',
                    body: initiative
                };
            },
        }),
        getInitiativeV2: builder.query({
            query: ({ workspaceId, initiativeId }) => `${BASE_URL_BASE}/v2/workspaces/${workspaceId}/initiatives/${initiativeId}`,
        }),
        updateInitiativeV2: builder.mutation({
            query: ({ workspaceId, initiativeId, initiative }) => {
                return {
                    url: `${BASE_URL_BASE}/v2/workspaces/${workspaceId}/initiatives/${initiativeId}`,
                    method: 'PUT',
                    body: initiative
                };
            },
        }),
        getInitiativesV2: builder.query({
            query: ({ workspaceId }) => `${BASE_URL_BASE}/v2/workspaces/${workspaceId}/initiatives`,
        }),
        deleteInitiativeV2: builder.mutation({
            query: ({ workspaceId, initiativeId }) => {
                return {
                    url: `${BASE_URL_BASE}/v2/workspaces/${workspaceId}/initiatives/${initiativeId}`,
                    method: 'DELETE',
                };
            },
        }),
        createInitiativeV2Mockup: builder.mutation({
            query: ({ workspaceId, mockup }) => {
                return {
                    url: `${BASE_URL_BASE}/v1/workspaces/${workspaceId}/blocks/initiativeV2Mockup`,
                    method: 'POST',
                    body: mockup
                };
            },
        }),

        getBlocks: builder.query({
            query: ({ workspaceId, blockIds }: { workspaceId: number, blockIds: number[] }) => `${BASE_URL_BASE}/v1/workspaces/${workspaceId}/blocks?ids=${blockIds.join(',')}`,
        }),
        createInitiativeCustomSections: builder.mutation({
            query: ({ workspaceId, initiativeId, newSections }) => {
                return {
                    url: `${BASE_URL_BASE}/v2/workspaces/${workspaceId}/initiatives/${initiativeId}/template-version`,
                    method: 'POST',
                    body: { fieldsConfig: newSections }
                }
            },
        }),
        getInitiativeTemplateVersion: builder.query({
            query: ({ workspaceId, templateId, versionId }) => `${BASE_URL_BASE}/v1/workspaces/${workspaceId}/initiative-templates/${templateId}/versions/${versionId}`,
        }),
        // ___________________________________New APIs
    })
});

export const {
    useGetInitiativeTemplatesQuery,
    useLazyGetInitiativeTemplatesQuery,
    useSendMessageMutation,
    useCreateInitiativeV2Mutation,
    useUpdateInitiativeV2Mutation,
    useGetInitiativeV2Query,
    useLazyGetInitiativeV2Query,
    useGetInitiativesV2Query,
    useLazyGetInitiativesV2Query,
    useDeleteInitiativeV2Mutation,
    useGetUserSessionQuery,
    useLazyGetUserSessionQuery,
    useCreateInitiativeV2MockupMutation,
    useGetBlocksQuery,
    useLazyGetBlocksQuery,
    useCreateInitiativeCustomSectionsMutation,
    useGetInitiativeTemplateVersionQuery,
    useLazyGetInitiativeTemplateVersionQuery,
} = initiativeApi