import {
    AdminPanelIcon,
    AssistantIcon,
    BookBookmark,
    Crosshair,
    FileText,
    GradingIcon,
    GridViewIcon,
    IntegrationsIcon,
    ListAltIcon,
    MetricIcon,
    Planet,
    PortalIcon,
    SquareHalf,
    Sticker,
    TeamIcon,
    TrafficSign,
    UserIcon,
    SignalCellularAltIcon,
    ChartBarIcon
} from 'components/icons';
import { Okr, OkrsList } from 'pages/Okrs';
import { Initiative, InitiativesList } from 'pages/Initiatives';
import Teams from 'pages/Teams';
import { ProductItem, ProductsList } from 'pages/Products';
import Users from 'pages/Users';
import Integrations from 'pages/Integrations';
import Roadmap from 'pages/Roadmap';
import Metrics from 'pages/Metrics';
import UserAccount from './components/UserAccount';
import UserLogo from 'common/UserLogo';
import Username from './components/Username';
import Assistant from 'pages/Assistant';
import { DependenciesList, DependencyView } from 'pages/Dependencies';
import { ReportMain, ReportsList } from 'pages/Reports';
import Ideas from 'pages/Ideas';
import JiraMapping from 'pages/Integrations/JiraMapping';
import InitiativeScores from 'pages/InitiativeScores';
import { SidebarMenu, SidebarMenuItem } from 'utils/types';
import Views from 'pages/Views';
import View from 'pages/Views/view';
import KBTeams from 'pages/KBTeams';
import KBGlossary from 'pages/KBGlossary';
import KBPersonas from 'pages/KBPersonas';
import KBGoals from 'pages/KBGoals';
import KBGeneral from '../../../pages/KBGeneral';
import KBCompany from 'pages/KBCompany';
import KBProducts from 'pages/KBProducts';
import KBUserFeedbacks from 'pages/KBUserFeedbacks';
import InitiativeTemplates from 'pages/InitiativeTemplates';
import Template from 'pages/InitiativeTemplates/components/Template';
import PlanningAnalysis from 'pages/PlanningAnalysis';
import RoadmapMetrics from 'pages/RoadmapMetrics';
import InitiativeManagement from 'pages/InitiativeManagement';
import InitiativesV2 from 'pages/InitiativesV2';

export const defaultMenuItems: SidebarMenu = {
    // views: {
    //     items: [],
    //     getMenuItems: (items: SidebarMenuItem[]) => { return items },
    //     maxLength: 3,
    //     actionButton: AddViewsButton,
    //     createButton: CreateViewsButton
    // },
    main: {
        items:
            [
                {
                    url: 'initiatives',
                    title: 'PRDs / Initiatives',
                    icon: SquareHalf,
                    component: InitiativesList,
                    featureFlag: 'initiativesMenuItem',
                    nestedRoutes: [
                        {
                            url: 'initiative',
                            component: Initiative
                        },
                        {
                            url: 'initiative/:id',
                            component: Initiative
                        },
                    ],
                },
                {
                    url: 'initiativesV2',
                    title: 'Initiatives V2',
                    icon: SquareHalf,
                    component: InitiativesV2,
                    featureFlag: 'initiativesV2MenuItem',
                    nestedRoutes: [
                        {
                            url: 'initiativeV2',
                            component: InitiativeManagement
                        },
                        {
                            url: 'initiativeV2/:id',
                            component: InitiativeManagement
                        },
                        // {
                        //     url: 'initiative/initiativeManagement',
                        //     component: InitiativeManagement,
                        // },
                    ],
                    beta: true,
                },
                {
                    url: 'views',
                    title: 'Execution views',
                    icon: () => <GridViewIcon />,
                    component: Views,
                    featureFlag: 'viewsMenuItem',
                    nestedRoutes: [
                        {
                            url: 'view',
                            component: View
                        },
                        {
                            url: 'view/:id',
                            component: View
                        },
                    ]
                },
                {
                    url: 'roadmapMetrics',
                    title: 'Roadmap metrics',
                    icon: () => <ChartBarIcon />,
                    component: RoadmapMetrics,
                    featureFlag: 'roadmapMetricsMenuItem',
                },
                {
                    url: 'roadmap',
                    title: 'Roadmap',
                    component: Roadmap,
                    icon: TrafficSign,
                    featureFlag: 'roadmapMenuItem',
                },
                {
                    url: 'catalog',
                    title: 'Product catalog',
                    component: ProductsList,
                    icon: PortalIcon,
                    featureFlag: 'productCatalogMenuItem',
                    nestedRoutes: [
                        {
                            url: 'product',
                            component: ProductItem,
                        },
                        {
                            url: 'product/:id',
                            component: ProductItem,
                        }
                    ]
                },
                {
                    url: 'okrs',
                    title: 'OKRs',
                    component: OkrsList,
                    icon: Crosshair,
                    featureFlag: 'okrsMenuItem',
                    nestedRoutes: [
                        {
                            url: 'okr',
                            component: Okr
                        },
                        {
                            url: 'okr/:id',
                            component: Okr
                        },
                    ]
                },
                {
                    url: 'dependencies',
                    title: 'Dependency tickets',
                    component: DependenciesList,
                    icon: Sticker,
                    featureFlag: 'dependenciesMenuItem',
                    nestedRoutes: [
                        {
                            url: 'dependency',
                            component: DependencyView,
                        },
                        {
                            url: 'dependency/:id',
                            component: DependencyView,
                        }
                    ]
                },
                {
                    url: 'reports',
                    title: 'Reports',
                    component: ReportsList,
                    icon: FileText,
                    featureFlag: 'reportsMenuItem',
                    nestedRoutes: [
                        {
                            url: 'report',
                            component: ReportMain,
                        },
                        {
                            url: 'report/:id',
                            component: ReportMain,
                        }
                    ]
                },
            ]
    },
    ideas: {
        items: [
            {
                title: 'Knowledge base',
                component: KBTeams,
                icon: BookBookmark,
                url: 'kb/teams',
                sections: [
                    {
                        url: 'kb/teams',
                        title: 'Teams',
                        component: KBTeams,
                    },
                    {
                        url: 'kb/glossary',
                        title: 'Glossary',
                        component: KBGlossary,
                    },
                    {
                        url: 'kb/personas',
                        title: 'Personas',
                        component: KBPersonas,
                    },
                    {
                        url: 'kb/goals',
                        title: 'Goals',
                        component: KBGoals,
                    },
                    {
                        url: 'kb/general',
                        title: 'General',
                        component: KBGeneral,
                    },
                    {
                        url: 'kb/company',
                        title: 'Company',
                        component: KBCompany,
                    },
                    {
                        url: 'kb/product',
                        title: 'Product',
                        component: KBProducts,
                    },
                    {
                        url: 'kb/user-feedbacks',
                        title: 'User Feedbacks',
                        component: KBUserFeedbacks,
                    }
                ],
            },
            // {
            //     url: 'knowledge-base',
            //     title: 'Knowledge base',
            //     component: KnowledgeBaseList,
            //     icon: BookBookmark,
            //     permission: Actions.isNotWebbFontainAndIsNotComcast,
            //     nestedRoutes: [
            //         {
            //             url: 'knowledge',
            //             component: KnowledgeBase,
            //         },
            //         {
            //             url: 'knowledge/:id',
            //             component: KnowledgeBase,
            //         }
            //     ]
            // },
            {
                url: 'ideas',
                title: 'Ideas',
                component: Ideas,
                icon: Planet,
                featureFlag: 'ideasMenuItem',
            },
            {
                url: 'planningAnalysis',
                title: 'Planning analysis',
                component: PlanningAnalysis,
                icon: () => <SignalCellularAltIcon />,
                featureFlag: 'planningAnalysisMenuItem',
                beta: true
            },
            // {
            //     url: 'assistant',
            //     title: 'Ask me anything',
            //     component: Assistant,
            //     icon: () => <AssistantIcon />,
            //     featureFlag: 'assistantMenuItem',
            // },
        ]
    },
    admin: {
        items: [
            {
                url: '',
                title: <Username />,
                icon: UserLogo,
                onBottom: true,
                component: UserAccount,
                hasPopupMenu: true,
            },
            {
                url: '',
                title: 'Admin panel',
                component: Teams,
                onBottom: true,
                icon: AdminPanelIcon,
                featureFlag: 'adminPanel',
                hidden: true,
                subMenus: [
                    {
                        url: 'users',
                        title: 'Users',
                        component: Users,
                        icon: () => <UserIcon />,
                        featureFlag: 'usersMenuItem',
                    },
                    {
                        url: 'teams',
                        title: 'Teams',
                        component: Teams,
                        featureFlag: 'teamsMenuItem',
                        icon: () => <TeamIcon />,
                    },
                    {
                        url: 'metrics',
                        title: 'Metrics',
                        component: Metrics,
                        icon: () => <MetricIcon />,
                        featureFlag: 'metricsMenuItem',
                    },
                    {
                        url: 'integrations',
                        title: 'Integrations',
                        component: Integrations,
                        featureFlag: 'integrationsMenuItem',
                        nestedRoutes: [
                            {
                                url: 'jiramapping',
                                component: JiraMapping,
                            }
                        ],
                        icon: () => <IntegrationsIcon />
                    },
                    {
                        url: 'initiativeScores',
                        title: 'Initiative scores',
                        component: InitiativeScores,
                        icon: () => <GradingIcon />,
                        featureFlag: 'initiativeScoresMenuItem',
                    },
                    {
                        url: 'initiativeTemplates',
                        title: 'Initiative Templates',
                        component: InitiativeTemplates,
                        icon: () => <ListAltIcon />,
                        featureFlag: 'initiativeTemplatesMenuItem',
                        beta: true,
                        nestedRoutes: [
                            {
                                url: 'template',
                                component: Template,
                            },
                            {
                                url: 'template/:id',
                                component: Template,
                            }
                        ]
                    },
                ]
            }
        ]

    }
}

export const getMenuItemsArray: (views?: any) => SidebarMenuItem[] = (views) => {
    return Object.values(defaultMenuItems).flatMap(el => {
        if (el.getMenuItems && views) {
            return views
        } else {
            return el.items
        }
    })
}