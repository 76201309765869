import { Flexbox, Typography, Tooltip, Loader } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { FC } from 'react';
import { ArrowDownIcon, ArrowCollapsedIcon } from 'components/icons';

const classes = classNames.bind(styles);

interface NavigationItemProps {
    title: string;
    icon: React.ReactNode;
    id?: string;
    activeSection?: string;
    scrollToSection?: (id: string) => void;
    isContentMinimized: boolean;
    className?: string;
    loading?: boolean;
    isExpandable?: boolean;
    isExpanded?: boolean;
    onToggleExpand?: () => void;
}

const NavigationItem: FC<NavigationItemProps> = ({
    title,
    icon,
    id,
    activeSection,
    scrollToSection,
    isContentMinimized,
    className,
    loading,
    isExpandable,
    isExpanded,
    onToggleExpand
}) => {
    const handleClick = (e: React.MouseEvent) => {
        if (isExpandable && onToggleExpand) {
            onToggleExpand();
        }
        if (scrollToSection && id) {
            scrollToSection(`initiative-${id}`);
        }
    };
    return (
        <Flexbox
            fullWidth
            align
            justify={isContentMinimized}
            className={classes('navigation-item', { active: activeSection === `initiative-${id}` }, className)}
            onClick={handleClick}
        >
            <Tooltip title={isContentMinimized ? title : ''} placement='right'>
                <Flexbox className={classes('navigation-item-icon')}>{icon}</Flexbox>
            </Tooltip>
            {!isContentMinimized && (
                <Flexbox align fullWidth justifyBetween>
                    <Typography className={classes('navigation-item-title', 'caption-2-regular', { loading })}>{title}</Typography>
                    {isExpandable && (
                        <Flexbox className={classes('expand-icon')}>
                            {isExpanded ? <ArrowDownIcon /> : <ArrowCollapsedIcon />}
                        </Flexbox>
                    )}
                </Flexbox>
            )}
            {loading && <Loader size={12} />}
        </Flexbox>
    )
}

export default NavigationItem;