import { Flexbox, Typography, CommonButton } from 'components'
import ContentSectionLayout from '../ContentSectionLayout'
import styles from './styles.module.scss'
import { PaintBrushBroadIcon, PlusIcon } from 'components/icons'
import classNames from 'classnames/bind'
import { FC, useEffect } from 'react'
import { blocksSelector, mockupsSelector, setBlocks, setSectionToRefine, editModeSelector } from 'store/initiative-slice'
import { useDispatch, useSelector } from 'react-redux'
import EmptyView from '../EmptyView'
import Mockup from './Mockup'
import { useWorkspaceId } from 'utils/hooks'
import { useLazyGetBlocksQuery } from 'store/initiative-api'
import { AttachmentMetadata, Block } from 'utils/types'
const classes = classNames.bind(styles)

// Custom empty view without icon and title
const MockupsEmptyView: FC<{ description: string }> = ({ description }) => {
    return (
        <EmptyView
            title=""
            description={description}
            showDefaultIcon={false}
        />
    );
};

interface MockupsProps {
}

const Mockups: FC<MockupsProps> = () => {
    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();
    const mockups = useSelector(mockupsSelector);
    const blocks = useSelector(blocksSelector);
    const editMode = useSelector(editModeSelector);
    const [getBlocks] = useLazyGetBlocksQuery();

    useEffect(() => {
        const loadBlocks = async () => {
            if (mockups?.length > 0) {
                const res = await getBlocks({ workspaceId: workspaceId, blockIds: mockups.map((mockup: any) => mockup.id) });
                if (res.data) {
                    const mockupBlocks: any[] = [];
                    res.data.forEach((b: Block<AttachmentMetadata>) => {
                        const m = mockups.find((mockup: any) => mockup.id === b.id);
                        mockupBlocks.push({ ...m, metadata: b.metadata })
                    })
                    dispatch(setBlocks(mockupBlocks));
                }
            } else {
                dispatch(setBlocks([]));
            }
        }

        loadBlocks();
    }, [mockups]);

    const handleGenerateMockups = () => {
        dispatch(setSectionToRefine({ key: 'mockups', type: 'generate', content: null }));
    };

    return (
        <ContentSectionLayout
            title="Mockups"
            icon={<PaintBrushBroadIcon />}
            id='initiative-mockups'
            isEmpty={!mockups?.length}
            dataSectionId='mockups'
            actions={
                editMode && (
                    <Flexbox align className={classes('actions-container', 'gap-1')} data-section-id='initiative-mockups'>
                        <CommonButton
                            buttonType='shadow'
                            buttonSize='small'
                            startIcon={<PlusIcon />}
                            onClick={handleGenerateMockups}
                        >
                            Add
                        </CommonButton>
                    </Flexbox>
                )
            }
        >
            {blocks?.length ? (
                <Flexbox align className={classes('mockups-container')} wrap data-section-id='mockups'>
                    {blocks.map((mockup: any) => (
                        <Mockup key={mockup.id} mockup={mockup} />
                    ))}
                </Flexbox>
            ) : (
                <MockupsEmptyView
                    description='Get visual mockups automatically based on your initiative.'
                />
            )}
        </ContentSectionLayout>
    )
}

export default Mockups