import { CommonButton, ConfirmationDialog, Flexbox, Typography } from 'components'
import styles from './styles.module.scss'
import classNames from 'classnames/bind'
import { FC, useState, useRef, useEffect } from 'react'
import ContentSectionLayout from '../ContentSectionLayout'
import { ArrowSquareOutIcon, CustomDeleteIcon, FileText, FileUploadIcon, LanguageIcon, DownloadSimpleIcon, PlusIcon } from 'components/icons'
import IconButtonComponent from 'components/IconButtonComponent'
import { useDispatch, useSelector } from 'react-redux'
import { editModeSelector, resourcesSelector, setEditMode, setRemovingAttachmentIds, setResources, setSectionToRefine } from 'store/initiative-slice';
import CustomBackdrop from 'components/CustomBackdrop'
import Input from '../Input/Input'
import EmptyView from '../EmptyView'
import { Block, isAttachmentBlock, isLinkBlock, LinkMetadata } from 'utils/types'
import FileSelector, { UploadedFile } from 'components/FileUploader'
import { AttachmentMetadata } from 'utils/types'
import { createAction } from 'common/resourcesBlock/index.api'
import { isValidHttpsUrl } from 'utils'
import { editKBLinkAttachment } from 'store/knowledgeBase-api'
const classes = classNames.bind(styles)

// Custom empty view without icon and title
const ResourcesEmptyView: FC<{ description: string }> = ({ description }) => {
    return (
        <EmptyView
            title=""
            description={description}
            showDefaultIcon={false}
        />
    );
};

interface ResourcesProps {
}

interface EditableBlock<T = AttachmentMetadata | LinkMetadata> extends Block<T> {
    editing?: boolean;
    isError?: boolean;
}

const Resources: FC<ResourcesProps> = () => {
    const dispatch = useDispatch()

    const resourcesSectionRef = useRef<HTMLDivElement>(null);

    const editMode = useSelector(editModeSelector);
    const resources = useSelector(resourcesSelector);

    const [addResourceOpen, setAddResourceOpen] = useState<{ open: boolean, type?: 'link' | 'file' }>({ open: false, type: 'link' });
    const [link, setLink] = useState<{ url: string, title: string }>({ url: '', title: '' });
    const [linkErrorMessage, setLinkErrorMessage] = useState('')
    const [editingLinkId, setEditingLinkId] = useState<null | number>(null)
    const [deletingResource, setDeletingResource] = useState<{ source: 'link' | 'file', id: number } | null>(null);

    const getLinkBlocks = () => {
        return resources.filter((el: any) => isLinkBlock(el)) as EditableBlock<LinkMetadata>[]
    }

    const getAttachmentBlocks = () => {
        return resources.filter((el: any) => isAttachmentBlock(el)) as EditableBlock<AttachmentMetadata>[]
    }

    const handleAddResource = (type: 'link' | 'file') => {
        dispatch(setEditMode(true));
        setAddResourceOpen({ open: true, type });
    }

    const onUploadFinish = (uploadedItem: EditableBlock<AttachmentMetadata>) => {
        dispatch(setResources([...resources, uploadedItem]))
    }

    const removeFile = (id: number) => {
        dispatch(setRemovingAttachmentIds([id]))
        const filtered = resources.filter((b: any) => b.id !== id)
        dispatch(setResources(filtered))
    }


    const onUploadStart = (files: UploadedFile[]) => {
        setAddResourceOpen({ open: false });
        const filesList: { [key: string]: UploadedFile } = {}
        for (let i = 0; i < files.length; i++) {
            filesList[files[i].name] = files[i]
        }
    }

    const openFile = (url?: string) => {
        if (url) {
            window.open(url, '_blank',)
        }
    }

    const removeLink = (id: number) => {
        dispatch(setRemovingAttachmentIds([id]))
        const filtered = resources.filter((b: any) => b.id !== id)
        dispatch(setResources(filtered))
    }

    const editLink = (link: EditableBlock<LinkMetadata>) => {
        setAddResourceOpen({ open: true, type: 'link' })
        setLink({ url: link.metadata.url, title: link.metadata.title || '' })
        setEditingLinkId(link.id)
    }

    const onCancelEditing = () => {
        setAddResourceOpen({ open: false })
        setLink({ url: '', title: '' })
        setEditingLinkId(null)
        setLinkErrorMessage('')
    }

    const onConfirm = async () => {
        try {
            setLinkErrorMessage('')
            if (editingLinkId) {
                await dispatch(editKBLinkAttachment({ blockId: editingLinkId, data: { url: link.url, title: link.title } }))
                dispatch(setResources(resources.map((el: any) => el.id === editingLinkId ? { ...el, metadata: { url: link.url, title: link.title } } : el)))
            } else {
                const linkMetadata = (await dispatch(createAction({ url: link.url, title: link.title }, 'blocks'))) as unknown as Block<LinkMetadata>
                dispatch(setResources([...resources, linkMetadata]))
            }
            setLink({ url: '', title: '' })
            setEditingLinkId(null)
        } catch (e: any) {
            if (e.data.error) {
                setLinkErrorMessage(e.data.error)
            }
        } finally {
            setAddResourceOpen({ open: false })
        }
    }

    useEffect(() => {
        if (!isValidHttpsUrl(link.url)) {
            setLinkErrorMessage('Should be a valid url')
        } else {
            setLinkErrorMessage('')
        }
    }, [link]);

    const handleDeleteResource = () => {
        if (deletingResource && deletingResource.source === 'link') {
            removeLink(deletingResource.id)
        } else if (deletingResource && deletingResource.source === 'file') {
            removeFile(deletingResource.id)
        }

        setDeletingResource(null);
    }

    return (
        <ContentSectionLayout
            title="Resources"
            icon={<FileText />}
            id='initiative-resources'
            className={classes('resources-wrapper-container')}
            actions={
                (editMode) && (
                    <Flexbox align className={classes('actions-container', 'gap-1')} data-section-id='initiative-resources'>
                        {!addResourceOpen.open ? (
                            <>
                                <CommonButton
                                    buttonType='shadow'
                                    buttonSize='small'
                                    startIcon={<PlusIcon />}
                                    onClick={() => handleAddResource('link')}
                                >
                                    Link
                                </CommonButton>
                                <CommonButton
                                    buttonType='shadow'
                                    buttonSize='small'
                                    startIcon={<PlusIcon />}
                                    onClick={() => handleAddResource('file')}
                                >
                                    File
                                </CommonButton>
                            </>
                        ) : (
                            <CommonButton buttonType='secondary' buttonSize='small' onClick={onCancelEditing}>
                                Cancel
                            </CommonButton>
                        )}
                    </Flexbox>
                )
            }
            isEmpty={!resources.length}
            dataSectionId='resources'
        >
            <Flexbox ref={resourcesSectionRef} vertical className={classes('resources-container', 'gap-4')}>
                {addResourceOpen.open && (
                    <>
                        <CustomBackdrop />
                        <Flexbox vertical justify className={classes('add-resource-container')} onClick={(e) => e.stopPropagation()}>
                            {addResourceOpen.type === 'link' && (
                                <>
                                    <Flexbox vertical className={classes('add-resource-container-content', 'gap-2')}>
                                        <Typography className={classes('add-resource-container-title', 'caption-2-medium')}>Title</Typography>
                                        <Input
                                            placeholder='Add Your Title'
                                            value={link.title}
                                            onChange={(e) => setLink({ ...link, title: e.target.value })}
                                            className={'title-input'}
                                            width={358}
                                        />
                                    </Flexbox>
                                    <Flexbox vertical className={classes('add-resource-container-content', 'gap-2')}>
                                        <Typography className={classes('add-resource-container-title', 'caption-2-medium')}>Link</Typography>
                                        <Input
                                            leftIcon={<LanguageIcon />}
                                            onClick={onConfirm}
                                            value={link.url}
                                            onChange={(e) => setLink({ ...link, url: e.target.value })}
                                            buttonText='Add'
                                            placeholder='Insert Your link here'
                                            buttonType='shadow'
                                            className={'link-input'}
                                            width={'100%'}
                                            disabled={!!link.url && !!linkErrorMessage}
                                        />
                                        {!!link.url && !!linkErrorMessage && (
                                            <Flexbox className="input-error">{linkErrorMessage}</Flexbox>
                                        )}
                                    </Flexbox>
                                </>
                            )}
                            {addResourceOpen.type === 'file' && (
                                <Flexbox vertical align justify className={classes('add-resource-file-container', 'gap-4')}>
                                    <FileUploadIcon />
                                    <Flexbox vertical className={classes('add-resource-file-container-content', 'gap-2')}>
                                        <Typography className={classes('paragraph-16-regular')}>Upload Your File</Typography>
                                        <Typography className={classes('paragraph-15-regular',)}>Add images, PDFs, Designs or any additional resources here.</Typography>
                                    </Flexbox>
                                    <FileSelector
                                        url={'blocks'}
                                        buttonType='button'
                                        multiple
                                        onUploadFinish={onUploadFinish}
                                        onUploadStart={onUploadStart}
                                        buttonRenderer={(openFilePicker) => (
                                            <CommonButton
                                                buttonType='secondary'
                                                buttonSize='medium'
                                                onClick={openFilePicker}
                                            >
                                                Choose File
                                            </CommonButton>
                                        )}
                                    />
                                </Flexbox>

                            )}
                        </Flexbox>
                    </>
                )}


                {(resources.length === 0) && <ResourcesEmptyView
                    description='Attach links, documents, or references that support your initiative.'
                />}
                {getAttachmentBlocks().length > 0 && (
                    // TODO: We will make this a common component
                    <Flexbox vertical className={classes('section-container', 'gap-2')}>
                        <Flexbox>
                            <Typography className={classes('section-title', 'caption-2-medium')}>Files</Typography>
                        </Flexbox>

                        <Flexbox vertical className={classes('section-items-container', 'gap-1')}>
                            {getAttachmentBlocks().map((file) => (
                                <Flexbox justifyBetween align key={file.id} className={classes('section-item-container')}>
                                    <Flexbox vertical className={classes('section-item-content-container', 'gap-2')}>
                                        <Typography className={classes('section-item-title')}>{file.metadata.fileName}</Typography>
                                        <Typography className={classes('paragraph-15-regular', 'section-item-description')}>
                                            {file.metadata.size}
                                            {/* • Uploaded by {file.metadata.uploadedBy} • {formatDate(file.metadata.uploadedDate, 'MMM dd, yyyy')} */}
                                        </Typography>
                                    </Flexbox>
                                    <Flexbox align className={classes('actions-container', 'gap-1')}>
                                        {editMode && (
                                            <IconButtonComponent buttonType='with-background' buttonSize='medium' onClick={() => {
                                                setDeletingResource({ source: 'file', id: file.id });
                                            }}>
                                                <CustomDeleteIcon />
                                            </IconButtonComponent>
                                        )}
                                        <IconButtonComponent buttonType='with-background' buttonSize='medium' onClick={() => openFile(file.metadata.signedUrl)}>
                                            <DownloadSimpleIcon />
                                        </IconButtonComponent>
                                    </Flexbox>
                                </Flexbox>
                            ))}
                        </Flexbox>
                    </Flexbox>
                )}

                {getLinkBlocks().length > 0 && (
                    <Flexbox vertical className={classes('section-container', 'gap-2')}>
                        <Flexbox>
                            <Typography className={classes('section-title', 'caption-2-medium')}>Links</Typography>
                        </Flexbox>

                        <Flexbox vertical className={classes('section-items-container', 'gap-1')}>
                            {getLinkBlocks().map((link) => (
                                <Flexbox justifyBetween align key={link.id} className={classes('section-item-container')}>
                                    <Flexbox vertical className={classes('section-item-content-container', 'gap-2')}>
                                        <Typography className={classes('section-item-title')}>{link.metadata.title}</Typography>
                                        {/* <Typography className={classes('paragraph-15-regular', 'section-item-description')}>
                                                Added by {link.addedBy} • {formatDate(link.addedDate, 'MMM dd, yyyy')}
                                            </Typography> */}
                                    </Flexbox>
                                    <Flexbox align className={classes('actions-container', 'gap-1')}>
                                        {editMode && (
                                            <IconButtonComponent buttonType='with-background' buttonSize='medium' onClick={() => {
                                                setDeletingResource({ source: 'link', id: link.id });
                                            }}>
                                                <CustomDeleteIcon />
                                            </IconButtonComponent>
                                        )}
                                        <IconButtonComponent buttonType='with-background' buttonSize='medium' onClick={() => openFile(link.metadata.url)}>
                                            <ArrowSquareOutIcon />
                                        </IconButtonComponent>
                                        {editMode && (
                                            <CommonButton buttonType='secondary' buttonSize='small' onClick={() => editLink(link)}>
                                                Edit
                                            </CommonButton>
                                        )}
                                    </Flexbox>
                                </Flexbox>
                            ))}
                        </Flexbox>
                    </Flexbox>
                )}
            </Flexbox>

            <ConfirmationDialog
                open={!!deletingResource}
                onClose={() => setDeletingResource(null)}
                onConfirm={handleDeleteResource}
                confirmButtonStyle='danger'
                title={`Delete this ${deletingResource?.source === 'link' ? 'link' : 'file'}?`}
            >
                <Flexbox>You're about to delete this {deletingResource?.source === 'link' ? 'link' : 'file'}.</Flexbox>
            </ConfirmationDialog>
        </ContentSectionLayout>
    )
}

export default Resources