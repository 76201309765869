import { CommonButton, ConfirmationDialog, Flexbox, IconButtonComponent, RichTextEditor, Typography } from 'components';
import styles from './styles.module.scss';
import generalStyles from '../styles.module.scss';
import classNames from 'classnames/bind';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeCustomSectionField, deleteCustomSection, editModeSelector, setSectionToRefine } from 'store/initiative-slice';
import { AIStarsIcon, CustomDeleteIcon } from 'components/icons';
import Input from '../Input/Input';

const classes = classNames.bind(styles);
const generalClasses = classNames.bind(generalStyles);

interface CustomSectionProps {
    sectionKey: string;
    data: any;
}

const CustomSection: FC<CustomSectionProps> = ({ sectionKey, data }) => {
    const dispatch = useDispatch();
    const editMode = useSelector(editModeSelector);

    const [deletingSectionConfirmation, setDeletingSectionConfirmation] = useState<boolean>(false);

    const handleChangeField = (fieldKey: string, value: any) => {
        dispatch(changeCustomSectionField({ sectionKey, fieldKey, value }));
    }

    const handleDeleteSection = () => {
        dispatch(deleteCustomSection(sectionKey));
    }

    const handleAIAction = (sectionId: string, type: 'generate' | 'refine', content: any) => {
        dispatch(setSectionToRefine({ key: sectionId, type: type, content: content }));
    }

    return (
        <Flexbox id={'initiative-' + sectionKey} vertical fullWidth className={classes('main-content-container')}>
            <Flexbox vertical className={classes('main-content')}>
                <Flexbox key={sectionKey} vertical className={classes('section', 'gap-3')}>
                    <Flexbox fullWidth>
                        {editMode ? (
                            <Flexbox fullWidth justifyBetween align>
                                <Flexbox
                                    fullWidth
                                    align
                                    className={classes('title-editor-input-container')}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Input
                                        className={'title-editor-input'}
                                        value={data.title}
                                        onChange={(e) => handleChangeField('title', e.target.value)}
                                        placeholder={'Title of the section'}
                                        inputClassName={classes('title-editor-input-input')}
                                    />
                                </Flexbox>

                                <Flexbox align justify className={classes('gap-1')}>
                                    <CommonButton buttonSize='small'
                                        buttonType='shadow'
                                        startIcon={<AIStarsIcon />}
                                        onClick={() => handleAIAction(sectionKey, 'generate', data)}
                                    >
                                        AI
                                    </CommonButton>
                                    <IconButtonComponent buttonType='with-background' buttonSize='medium'
                                        onClick={() => setDeletingSectionConfirmation(true)}
                                    >
                                        <CustomDeleteIcon />
                                    </IconButtonComponent>
                                </Flexbox>
                            </Flexbox>
                        ) : (
                            <Typography className={classes('body-bold')}>{data.title || 'Untitled Section'}</Typography>
                        )}
                    </Flexbox>
                    <Flexbox vertical className={generalClasses('initiative-editor-container', { 'none-edit-mode': !editMode })}>
                        <RichTextEditor
                            value={data.description}
                            onChange={(value) => handleChangeField('description', value)}
                            placeholder={'Type in your content here...'}
                            disabled={!editMode}
                            insertButton
                            newVersion
                        />
                    </Flexbox>
                </Flexbox>
            </Flexbox>
            <ConfirmationDialog
                open={deletingSectionConfirmation}
                onClose={() => setDeletingSectionConfirmation(false)}
                onConfirm={handleDeleteSection}
                confirmButtonStyle='danger'
                title={'Delete this section?'}
            >
                <Flexbox>You're about to delete this section.</Flexbox>
            </ConfirmationDialog>
        </Flexbox>
    )
}

export default CustomSection;
