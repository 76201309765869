import { CommonButton, Flexbox, IconButtonComponent, Tooltip, Typography } from 'components'
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ArrowLeftIcon, PlusIcon, SidebarIcon, FileText } from 'components/icons';
import {
    dragKeySelector,
    editModeSelector,
    insightsOpenSelector,
    isContentMinimizedSelector,
    requirementsSelector,
    reorderRequirements,
    sectionsToShowSelector,
    setContentMinimized,
    setDragKey,
    setInsightsOpen,
} from 'store/initiative-slice';
import { useDispatch, useSelector } from 'react-redux';
import NavigationItem from './NavigationItem';
// @ts-ignore
import RequirementItem from './RequirementItem';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useAddCustomSection } from 'utils/hooks';

const classes = classNames.bind(styles);

interface TableOfContentsProps {
    onNavigateBack: () => void;
}

const TableOfContents = ({ onNavigateBack }: TableOfContentsProps) => {
    const dispatch = useDispatch();

    const { handleAddCustomSection } = useAddCustomSection();

    const [activeSection, setActiveSection] = useState('initiative-project-overview');
    const [activeRequirement, setActiveRequirement] = useState<string | null>(null);
    const [expandedSections, setExpandedSections] = useState<string[]>(['initiative-requirements']);
    const [isDragging, setIsDragging] = useState(false);

    const sections = useSelector(sectionsToShowSelector);
    const requirements = useSelector(requirementsSelector) || [];
    const dragKey = useSelector(dragKeySelector);
    const editMode = useSelector(editModeSelector);
    const insightsOpen = useSelector(insightsOpenSelector);
    const isContentMinimized = useSelector(isContentMinimizedSelector);

    const handleToggleInsights = () => {
        dispatch(setInsightsOpen(!insightsOpen));
    }

    const toggleSectionExpand = (sectionId: string) => {
        setExpandedSections(prev =>
            prev.includes(sectionId)
                ? prev.filter(id => id !== sectionId)
                : [...prev, sectionId]
        );
    }

    const scrollToSection = (sectionId: string) => {
        if (sectionId.startsWith('requirement-')) {
            setActiveRequirement(sectionId);
            // Auto-expand requirements section if needed
            if (!expandedSections.includes('initiative-requirements')) {
                setExpandedSections(prev => [...prev, 'initiative-requirements']);
            }
        } else {
            setActiveRequirement(null);
            setActiveSection(sectionId);
        }

        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            // Update URL
            const sectionName = sectionId.replace('initiative-', '').replace('requirement-', 'req-');
            window.history.pushState({}, '', `#${sectionName}`);
        }
    };

    const handleDragStart = () => {
        setIsDragging(true);
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            setIsDragging(false);
            return;
        }

        dispatch(reorderRequirements({
            sourceIndex: result.source.index,
            destinationIndex: result.destination.index
        }));

        setTimeout(() => {
            setIsDragging(false);
            dispatch(setDragKey(dragKey + 1));
        }, 0);
    };

    // Handle initial URL hash
    useEffect(() => {
        const hash = window.location.hash.replace('#', '');
        if (hash) {
            if (hash.startsWith('req-')) {
                const requirementId = hash.replace('req-', '');
                scrollToSection(`requirement-${requirementId}`);
            } else {
                const sectionId = `initiative-${hash}`;
                scrollToSection(sectionId);
            }
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Check if any requirement is in view
            const requirementElements = document.querySelectorAll('[data-requirement-id]');
            if (requirementElements.length) {
                const visibleRequirements = Array.from(requirementElements).map(req => {
                    const rect = req.getBoundingClientRect();
                    const requirementId = req.getAttribute('data-requirement-id') || '';
                    return {
                        id: `requirement-${requirementId}`,
                        top: rect.top,
                        bottom: rect.bottom,
                        height: rect.height,
                        visiblePercentage: getVisiblePercentage(rect)
                    };
                }).filter(req => req.visiblePercentage > 0);

                if (visibleRequirements.length) {
                    // Sort by visibility and position from top
                    const bestRequirement = visibleRequirements.reduce((best, current) => {
                        if (current.visiblePercentage > best.visiblePercentage + 20) {
                            return current;
                        }
                        if (Math.abs(current.visiblePercentage - best.visiblePercentage) <= 20) {
                            return Math.abs(current.top) < Math.abs(best.top)
                                ? current
                                : best;
                        }
                        return best;
                    });
                    setActiveRequirement(bestRequirement.id);
                    setActiveSection('initiative-requirements');
                    // Update URL without triggering navigation
                    const reqIdForUrl = bestRequirement.id.replace('requirement-', 'req-');
                    window.history.replaceState({}, '', `#${reqIdForUrl}`);
                    return;
                }
            }
            // Fall back to section tracking if no requirements are visible
            const sectionElements = document.querySelectorAll('[data-section-id]');
            if (!sectionElements.length) {
                return;
            }
            // Find all visible sections
            const visibleSections = Array.from(sectionElements).map(section => {
                const rect = section.getBoundingClientRect();
                const sectionId = section.getAttribute('data-section-id') || '';

                return {
                    id: sectionId,
                    top: rect.top,
                    bottom: rect.bottom,
                    height: rect.height,
                    visiblePercentage: getVisiblePercentage(rect)
                };
            }).filter(section => section.visiblePercentage > 0);

            if (!visibleSections.length) { return; }

            // Sort sections by visible percentage and distance from top
            const bestSection = visibleSections.reduce((best, current) => {
                // If a section is significantly more visible, prefer it
                if (current.visiblePercentage > best.visiblePercentage + 20) {
                    return current;
                }

                // If visibility is similar, prefer the one closer to the top
                if (Math.abs(current.visiblePercentage - best.visiblePercentage) <= 20) {
                    return Math.abs(current.top) < Math.abs(best.top)
                        ? current
                        : best;
                }

                return best;
            });

            setActiveSection(`initiative-${bestSection.id}`);
            setActiveRequirement(null);
            // Update URL
            const sectionName = bestSection.id.replace('initiative-', '');
            window.history.replaceState({}, '', `#${sectionName}`);
        };
        // Helper function to calculate visible percentage of an element
        const getVisiblePercentage = (rect: any) => {
            const windowHeight = window.innerHeight;

            if (rect.top > windowHeight || rect.bottom < 0) {
                return 0;
            }

            const visibleHeight = Math.min(rect.bottom, windowHeight) -
                Math.max(rect.top, 0);

            return (visibleHeight / rect.height) * 100;
        };

        let observer: MutationObserver;

        const setupScrollHandler = () => {
            const container = document.getElementById('initiative-management');
            if (!container) { return; }

            container.addEventListener('scroll', handleScroll);
            // Initial check
            handleScroll();
            // Cleanup previous observer if exists
            if (observer) { observer.disconnect(); }
        };

        // Create an observer instance to watch for DOM changes
        observer = new MutationObserver((mutations, obs) => {
            if (document.getElementById('initiative-management')) {
                setupScrollHandler();
            }
        });

        // Start observing
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });

        // Try initial setup
        setupScrollHandler();

        return () => {
            const container = document.getElementById('initiative-management');
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
            observer.disconnect();
        };
    }, []);

    const handleToggleMinimizeContent = () => {
        dispatch(setContentMinimized(!isContentMinimized));
    }

    const renderRequirementItems = () => {
        if (isContentMinimized || !requirements.length) {
            return null;
        }
        // If not in edit mode, render non-draggable requirements
        if (!editMode) {
            return (
                <Flexbox
                    vertical
                    className={classes('indented-items')}
                >
                    {requirements.map((requirement: any, index: number) => {
                        const requirementId = `requirement-${requirement.field_generation_id}`;
                        const isActive = activeRequirement === requirementId;
                        return (
                            <div
                                key={requirement.field_generation_id || index}
                                className={classes('requirement-draggable', { active: isActive })}
                            >
                                <RequirementItem
                                    title={requirement.content?.title || 'Untitled Requirement'}
                                    description={requirement.content?.description || ''}
                                    requirementId={requirement.field_generation_id}
                                    isContentMinimized={isContentMinimized}
                                    isActive={isActive}
                                    scrollToSection={() => scrollToSection(requirementId)}
                                />
                            </div>
                        );
                    })}
                </Flexbox>
            );
        }

        // In edit mode, render draggable requirements
        return (
            <DragDropContext
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
            >
                <Droppable droppableId="sidebar-requirements">
                    {(provided) => (
                        <Flexbox
                            vertical
                            className={classes('indented-items')}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {requirements.map((requirement: any, index: number) => {
                                const requirementId = `requirement-${requirement.field_generation_id}`;
                                const isActive = activeRequirement === requirementId;
                                return (
                                    <Draggable
                                        key={requirement.field_generation_id || index}
                                        draggableId={`sidebar-${requirement.field_generation_id}`}
                                        index={index}
                                        isDragDisabled={isDragging}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={classes('requirement-draggable', {
                                                    dragging: snapshot.isDragging,
                                                    active: isActive
                                                })}
                                            >
                                                <RequirementItem
                                                    title={requirement.content?.title || 'Untitled Requirement'}
                                                    description={requirement.content?.description || ''}
                                                    requirementId={requirement.field_generation_id}
                                                    isContentMinimized={isContentMinimized}
                                                    isActive={isActive}
                                                    scrollToSection={() => scrollToSection(requirementId)}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </Flexbox>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    return (
        <Flexbox vertical justifyBetween className={classes('initiative-sidePanel', { expanded: !isContentMinimized, 'animating': true })} id='initiative-sidePanel'>
            <Flexbox vertical className={classes('initiative-sidePanel-content', 'gap-1')} align={isContentMinimized}>
                <Flexbox justifyBetween align className={classes('initiative-sidePanel-content-header')}>
                    <Flexbox align className={classes('header-left')}>
                        <IconButtonComponent onClick={onNavigateBack} buttonType='with-background' buttonSize='medium' className={classes('back-button')}>
                            <ArrowLeftIcon />
                        </IconButtonComponent>
                        {!isContentMinimized && <Typography className={classes('title')}>Table of Contents</Typography>}
                    </Flexbox>
                </Flexbox>
                <Flexbox vertical className={classes('navigation')} style={{ overflowY: 'auto' }}>
                    <Flexbox vertical className={classes('navigation-main')}>
                        {sections?.map(({ title, icon, id, loading }: any) => {
                            const Icon = icon || FileText;
                            const isRequirementsSection = id === 'requirements';
                            const isExpanded = expandedSections.includes(`initiative-${id}`);
                            return (
                                <Flexbox vertical key={id} className={classes('section-container')}>
                                    <NavigationItem
                                        title={title}
                                        icon={<Icon />}
                                        id={id}
                                        activeSection={activeSection}
                                        scrollToSection={scrollToSection}
                                        isContentMinimized={isContentMinimized}
                                        loading={loading}
                                        isExpandable={isRequirementsSection && requirements.length > 0}
                                        isExpanded={isExpanded}
                                        onToggleExpand={() => isRequirementsSection && toggleSectionExpand(`initiative-${id}`)}
                                    />
                                    {isRequirementsSection && requirements.length > 0 && isExpanded && !isContentMinimized && renderRequirementItems()}
                                </Flexbox>
                            )
                        })}
                    </Flexbox>
                    {!isContentMinimized ? (
                        <CommonButton className={classes('add-new-section', 'expanded')} buttonSize='small' buttonType='text' startIcon={<PlusIcon />} onClick={() => handleAddCustomSection()}>
                            Add New Section
                        </CommonButton>
                    ) : (
                        <Tooltip title='Add New Section' placement='right'>
                            <IconButtonComponent className={classes('add-new-section')} buttonSize='medium' buttonType='without-background' onClick={() => handleAddCustomSection()}>
                                <PlusIcon />
                            </IconButtonComponent>
                        </Tooltip>
                    )}
                </Flexbox>
            </Flexbox>
            <Flexbox fullWidth className={classes('collapse-button-container')}>
                <CommonButton
                    onClick={handleToggleMinimizeContent}
                    buttonType='shadow'
                    buttonSize='medium'
                    className={classes('collapse-button', { 'collapsed': isContentMinimized })}
                    fullWidth={!isContentMinimized}
                >
                    <SidebarIcon />
                    {!isContentMinimized && <Typography className={classes('collapse-text')}>Collapse</Typography>}
                </CommonButton>
            </Flexbox>
        </Flexbox>
    );
};

export default TableOfContents;