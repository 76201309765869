import { Flexbox, Loader, Typography } from 'components'
import Overview from './Overview';
import Resources from './Resources';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Requirements from './Requirements';
import Heading from './Heading';
import { useEffect, useState } from 'react';
import MainContent from './MainContent';
import AssistantPanel from './AssistantPanel';
import TableOfContents from './TableOfContents';
import {
    BugIcon,
    CircleWavyCheckIcon,
    CheckCircleIconCustom,
    GhostIcon
} from 'components/icons';
import Mockups from './Mockups';
import OkrImpact from './OkrImpact';
import ScoreSidePanel from './ScoreSidePanel';
import { useSelector, useDispatch } from 'react-redux';
import {
    editModeSelector,
    insightsOpenSelector,
    isLoadingTemplatesSelector,
    setEditMode,
    resetInitiativeState,
    currentInitiativeSelector,
    selectedTemplateSelector,
    flattenInitiative,
    setCurrentInitiative,
    sessionIdSelector,
    setUnsavedChanges,
    unsavedChangesSelector,
    setRemovingAttachmentIds,
    removingAttachmentIdsSelector,
    mainSections,
    setSelectedTemplate,
    customSectionInteractionSelector,
    setCustomSectionInteraction,
} from 'store/initiative-slice';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import CustomConfirmationDialog from 'components/CustomConfirmationDialog';
import { useLazyGetInitiativeV2Query, useUpdateInitiativeV2Mutation, useLazyGetUserSessionQuery, useLazyGetInitiativeTemplateVersionQuery, useCreateInitiativeCustomSectionsMutation } from 'store/initiative-api';
import { useWorkspaceId } from 'utils/hooks';
import { userSelector } from 'store/user';
import { deleteBlockFromKB } from 'store/knowledgeBase-api';
import CustomSection from './CustomSection';

const classes = classNames.bind(styles)

export enum Mode {
    EDIT = 'edit',
    VIEW = 'view'
}

// @mocked sections
export const mockedSections = [
    { title: 'Problem Statement', id: 'problem', icon: <BugIcon /> },
    { title: 'Validation', id: 'validation', icon: <CheckCircleIconCustom /> },
    { title: 'High Level Solution', id: 'solution', icon: <CircleWavyCheckIcon /> },
]

const InitiativeManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const workspaceId = useWorkspaceId();
    const params = useParams();

    const [getInitiative, { isLoading: isLoadingInitiative }] = useLazyGetInitiativeV2Query();
    const [getInitiativeTemplateVersion] = useLazyGetInitiativeTemplateVersionQuery();
    const [updateInitiativeV2] = useUpdateInitiativeV2Mutation();
    const [getUserSession, { isLoading: isLoadingUserSession }] = useLazyGetUserSessionQuery();
    const [createInitiativeCustomSections] = useCreateInitiativeCustomSectionsMutation();

    useEffect(() => {
        if (params.id) {
            getInitiative({ workspaceId, initiativeId: params.id }).then((initiativeRes) => {
                getInitiativeTemplateVersion({
                    workspaceId,
                    templateId: initiativeRes.data.templateId,
                    versionId: initiativeRes.data.templateVersion
                }).then((res) => {
                    dispatch(setSelectedTemplate(res.data));
                    dispatch(setCurrentInitiative({ initiative: initiativeRes.data, template: res.data }));
                })
            })
        }
    }, [params.id]);

    const editMode = useSelector(editModeSelector);
    const insightsOpen = useSelector(insightsOpenSelector);
    const isLoadingTemplates = useSelector(isLoadingTemplatesSelector);
    const currentInitiative = useSelector(currentInitiativeSelector);
    const user = useSelector(userSelector);
    const sessionId = useSelector(sessionIdSelector);
    const unsavedChanges = useSelector(unsavedChangesSelector);
    const removingAttachmentIds = useSelector(removingAttachmentIdsSelector);
    const customSectionInteraction = useSelector(customSectionInteractionSelector);
    const selectedTemplate = useSelector(selectedTemplateSelector);

    const [showLeaveDialog, setShowLeaveDialog] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState('');

    // Close the browser tab/window, refresh page, or navigate away
    useEffect(() => {
        if (!editMode || !unsavedChanges) { return; }

        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            e.preventDefault();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [editMode, unsavedChanges]);

    // Handle browser back/forward navigation
    useEffect(() => {
        if (!editMode || !unsavedChanges) { return; }

        window.addEventListener('popstate', () => {
            window.history.pushState(null, '', location.pathname);
            handleNavigate();
        });

        return () => {
            window.removeEventListener('popstate', () => { });
        };
    }, [location, editMode, unsavedChanges]);


    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);

        const handlePopState = () => {
            window.history.pushState(null, '', location.pathname);

            if (editMode && unsavedChanges) {
                setPendingNavigation('/initiativesV2');
                setShowLeaveDialog(true);
            } else {
                navigate('/initiativesV2');
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate, editMode, unsavedChanges, location.pathname]);

    useEffect(() => {
        const getSizes = () => {
            const sidebarElement = document.getElementById('initiative-sidePanel');
            const sidebarInsightsElement = document.getElementById('initiative-sidePanel-insights');
            const headingElement = document.getElementById('initiative-heading');

            if (sidebarElement) {
                document.documentElement.style.setProperty('--initiative-sidebar-width', `${sidebarElement.clientWidth + (sidebarInsightsElement ? (sidebarInsightsElement.clientWidth + 8) : 0)}px`);
            }

            if (headingElement) {
                document.documentElement.style.setProperty('--initiative-heading-width', `${headingElement.clientWidth}px`);
            }
        };

        // Initial measurement
        getSizes();

        // Create observer to watch for size changes
        const resizeObserver = new ResizeObserver(getSizes);
        const sidebarElement = document.getElementById('initiative-sidePanel');
        const headingElement = document.getElementById('initiative-heading');
        const sidebarInsightsElement = document.getElementById('initiative-sidePanel-insights');

        if (sidebarElement) { resizeObserver.observe(sidebarElement); }
        if (headingElement) { resizeObserver.observe(headingElement); }
        if (sidebarInsightsElement) { resizeObserver.observe(sidebarInsightsElement); }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if (sessionId) {
            getUserSession({ userId: user.id, sessionId: sessionId, workspaceId })
        }
    }, [sessionId]);

    const handleNavigate = () => {
        if (editMode && unsavedChanges) {
            setPendingNavigation('/initiativesV2');
            setShowLeaveDialog(true);
        } else {
            navigate('/initiativesV2');
        }
    };

    const handleSaveInitiative = async () => {
        if (customSectionInteraction) {
            await createInitiativeCustomSections({
                workspaceId,
                initiativeId: params.id,
                newSections: selectedTemplate?.content,
            });
            dispatch(setCustomSectionInteraction(false));
        }

        await updateInitiativeV2({
            workspaceId,
            initiativeId: params.id,
            initiative: {
                fields: flattenInitiative(currentInitiative || {}),
                chatSessionId: sessionId
            }
        });

        if (removingAttachmentIds.length > 0) {
            removingAttachmentIds.forEach(id => {
                dispatch(deleteBlockFromKB(id));
            });

            dispatch(setRemovingAttachmentIds([]));
        }
    }

    const handleLeaveWithoutSaving = () => {
        dispatch(setUnsavedChanges(false));
        if (editMode) {
            dispatch(setEditMode(false));
        }
        dispatch(resetInitiativeState());
        setShowLeaveDialog(false);
        navigate(pendingNavigation);
    };

    const handleSaveAndLeave = async () => {
        dispatch(setUnsavedChanges(false));
        await handleSaveInitiative();

        if (editMode) {
            dispatch(setEditMode(false));
        }
        dispatch(resetInitiativeState());
        setShowLeaveDialog(false);
        navigate(pendingNavigation);
    };

    // Also add cleanup on component unmount
    useEffect(() => {
        return () => {
            dispatch(resetInitiativeState());
        };
    }, [dispatch]);

    return (
        <>
            {(isLoadingTemplates || isLoadingInitiative) ? (
                <Flexbox align justify fullHeight fullWidth>
                    <Loader />
                </Flexbox>
            ) : (
                <>
                    {insightsOpen && <ScoreSidePanel />}
                    {currentInitiative && (
                        <Flexbox fullWidth className={classes('initiative-management')}>
                            <TableOfContents onNavigateBack={handleNavigate} />
                            <Flexbox vertical fullWidth className={classes('initiative-management-content')}>
                                <Heading />
                                <Flexbox justify fullWidth id='initiative-management' className={classes('initiative-management-content-main-container', { edit: editMode })}>
                                    <Flexbox vertical fullWidth align className={classes('initiative-management-content-main')}>
                                        <Overview />
                                        <MainContent />
                                        <OkrImpact />
                                        <Requirements />
                                        <Mockups />
                                        <Resources />
                                        {/* Render custom sections that are not in mainSections */}
                                        {currentInitiative && Object.entries(currentInitiative)
                                            .filter(([key]) => !mainSections.includes(key))
                                            .map(([sectionKey, sectionData]) => (
                                                <CustomSection
                                                    key={sectionKey}
                                                    sectionKey={sectionKey}
                                                    data={sectionData[0].value}
                                                />
                                            ))
                                        }
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>
                            <Flexbox vertical fullWidth className={classes('initiative-management-assistant-panel')}>
                                {isLoadingUserSession ? (
                                    <Flexbox align justify fullHeight fullWidth>
                                        <Loader />
                                    </Flexbox>
                                ) : (
                                    <AssistantPanel />
                                )}
                            </Flexbox>
                        </Flexbox>
                    )}
                    <CustomConfirmationDialog
                        open={showLeaveDialog}
                        onClose={() => setShowLeaveDialog(false)}
                        onConfirm={handleSaveAndLeave}
                        onCancel={handleLeaveWithoutSaving}
                        confirmButtonLabel="Save & Close"
                        centerIcon={<GhostIcon />}
                        cancelButtonLabel="Leave"
                        cancelButton
                        confirmButton
                        buttonsCenter
                    >
                        <Flexbox vertical align className={classes('leave-dialog-content')}>
                            <Typography className={classes('title', 'paragraph-16-regular')}>Leaving Initiative</Typography>
                            <Typography className={classes('description', 'paragraph-15-regular')}>If you leave now, your changes won't be saved. To save them, click "Save and Close."</Typography>
                        </Flexbox>
                    </CustomConfirmationDialog>
                </>
            )}
        </>
    )
}

export default InitiativeManagement